import React, {useState, useEffect} from "react";
import SignIn from "./SignIn";
import { useLocation, useNavigate } from "react-router-dom";
import Register from "./Register";
import SnackbarNotify from "../components/SnackbarNotify";
import PasswordChange from "./PasswordChange";
import PasswordReset from "./PasswordReset";


const LoginRegisterPanel = (props) => {
  const {signInOpen, signUpOpen, handleClose, handleCloseSignUp, handleOpenSignIn, handleOpenSignUp} = props
  const [showNotificaton, setShowNotfication] = useState(false)
  const [pwdChangeOpen, setPwdChangeOpen] = useState(false)
  const [pwdResetOpen, setPwdResetOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname === '/login') {
      handleOpenSignIn()
    }
    if (location.pathname === '/register') {
      handleOpenSignUp()
    }
    if (location.pathname === '/password-reset') {
      setPwdResetOpen(true)
    }
    if (location.pathname.startsWith('/password-reset-confirm')) {
      setPwdChangeOpen(true)
    }

  }, [location?.pathname])

  // useEffect(() => {
  //   // when signInOpen closes, navigate to home
  //   if (!signInOpen) {
  //     window.location.href = "/";
  //   }
  // }, [signInOpen])

  const handleCloseNotification = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowNotfication(false);
  }; 

  return (
    <>
      <SignIn open={signInOpen} handleClose={handleClose}/>
      <Register setMessage={setMessage} setShowNotfication={setShowNotfication} setSuccess={setSuccess}
        open={signUpOpen} handleClose={handleCloseSignUp} openSignIn={handleOpenSignIn}/>
      <PasswordChange open={pwdChangeOpen} handleClose={setPwdChangeOpen} setMessage={setMessage} setShowNotfication={setShowNotfication}/>
      <PasswordReset  open={pwdResetOpen} handleClose={setPwdResetOpen} setMessage={setMessage} setShowNotfication={setShowNotfication}/>
      <SnackbarNotify success={success} open={showNotificaton} message={message} handleClose={handleCloseNotification} />
    </>
  );
}

export default LoginRegisterPanel;
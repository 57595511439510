import React, {useEffect, useState} from 'react'
import {fetchParsedDocument} from './actions/fetchParsedDocument'
import ParsedDocument from './ParsedDocument'

const ParsedDocumentPage = (props) => {
  const [parsedDocument, setParsedDocument] = useState(null)
  const parsedDocumentMeta = props.parsedDocumentMeta
  const docId = parsedDocumentMeta.document_identifier
  const title = parsedDocumentMeta.document_title_text
  const {matchedParagraphs} = props
  const docMatchedParagraphs = matchedParagraphs ? matchedParagraphs[parsedDocumentMeta?.id] : null

  useEffect(() => {
    // console.log(props)
    // console.log('docId', docId)
    fetchParsedDocument(docId || parsedDocumentMeta).then(res => {
      setParsedDocument(res.data)
    })
  }, [])

  return (
    <div>
      <ParsedDocument matchedParagraphs={docMatchedParagraphs} document={parsedDocument} title={title}/>
    </div>

  )
}

export default ParsedDocumentPage
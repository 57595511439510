
import React, { useState, useEffect } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MyTags from "./MyTags";
import TagDocsPage from "./TaggedDocsPage";
import fetchTaggedDocs from './actions/fetchTaggedDocs';
import DocumentPage from "../resultTabs/DocumentPage";
import { useNavigate  } from 'react-router-dom';
import './TagsPage.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4}}>
         {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const TagsPage = (props) => {

  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [viewParsedDocument, setViewParsedDocument] = useState(false)
  const [run, setRun] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const steps = [
    {
      target: ".docs-tagged-page",
      title: "Tagged Documents",
      content: <div> 
        <p className="walkstep"> Here you can find all the documents you have tagged before, as well as untag them.</p>
        <p className="walkstep"> Clicking on a document adds it to the view.</p>
        </div>,
      spotlightClicks: true,
      disableBeacon: true,
      
    },
    {
      target: ".MuiTabs-vertical",
      title: "Tags panel navigation",
      content: <div> 
        <p className="walkstep"> We're in <span style={{fontStyle: 'italic'}}>Tagged Documents</span> currently. </p>
        <p className="walkstep"> You can explore the document within the familiar  <span style={{fontStyle: 'italic'}}> Document View </span> tab.</p>
        <p className="walkstep"> Now lets take a closer look at <span style={{fontStyle: 'italic'}}> My Tags. </span> <b> Press next.</b></p>
      </div>,
      spotlightClicks: true,
    },
    {
      target: "#vertical-tab-0",
      content: <div> 
        <p className="walkstep"> Click on My Tags to change the tab</p>
      </div>,
      spotlightClicks: true,
    },
    {
      target: " #my-tags-page",
      title: "My tags page",
      content: <div> 
        <p className="walkstep"> On this page you can manage your tags.</p>
        <p className="walkstep"> Creating a new tag requires a name and color. </p>
        <p className="walkstep"> After writing a name and choosing color, clicking create adds it. </p>
      </div>,
      spotlightClicks: true,
    },
    {
      target: " .MuiDataGrid-root",
      title: "My tags page",
      content: <div> 
        <p className="walkstep"> You can also see how many documents you tagged here. </p>
        <p className="walkstep"> To see the files, Tagged documents is used</p>
        <p className="walkstep"> This concludes our walkthrough. Enjoy using Xlege! </p>
      </div>,
      spotlightClicks: true,
    },
  ]

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setTourStep(0);
      sessionStorage.removeItem('walkthrough')
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourStep(nextStepIndex);
      if (nextStepIndex === 4) {
        sessionStorage.removeItem('walkthrough')
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // change url
    if (run && newValue == 0 && tourStep == 2) {
      setTourStep(3)
    }
    navigate(`?tab=${newValue == 0? 'my' : newValue == 1? 'tagged-documents' : 'document-view'}`);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab == 'my') {
      setValue(0)
      if (run && tourStep == 2) {
        setTourStep(3)
      }
    } else if (tab == 'tagged-documents') {
      setValue(1)
    } else if (tab == 'document-view') {
      setValue(2)
    }
  }, [])



  // check session storage walkthrough
  useEffect(() => {
    const walkthrough = sessionStorage.getItem('walkthrough')
    if (walkthrough) {
      setRun(true)
    }
  }, [])

  const [taggedDocs, setTaggedDocs] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])

  const clearDocuments = () => {
    setSelectedDocuments([])
  }
  const removeDocument = (id) => {
    let newDocuments 
    newDocuments = selectedDocuments.filter((doc) => doc !== id)
    setSelectedDocuments(newDocuments)
  }

  const overWriteDocuments = (idsList) => {
    console.log('overwriting documents', idsList)
    setSelectedDocuments(idsList)
  }

  const selectDocuments = (id) => {
    if (typeof id == 'number' || typeof id == 'string') {
      setSelectedDocuments([...selectedDocuments, id])
    } else {
      setSelectedDocuments([...selectedDocuments, ...id])
    }
  }

  useEffect(() => {
    fetchTaggedDocs().then((response) => {
      setTaggedDocs(response.data)
    })
  }, [])

  return (
    <Container maxWidth="xl" style={{padding: 0, minHeight: '40vh'}}>
      <Joyride
      run={run} 
      continuous 
      showProgress 
      data-testid="joyride" 
      steps={steps}
      stepIndex={tourStep}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: '#e3ffeb',
          primaryColor: '#1976d2',
          textColor: '#004a14',
          width: 500,
          zIndex: 1000,
          beaconSize: 50,
        }
    }}
    ></Joyride>

    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
  
        <Tab label="My tags" {...a11yProps(0)} />
        <Tab label="Tagged documents" {...a11yProps(1)} />
        <Tab label="Document view" {...a11yProps(2)} />
        </Tabs>
        <TabPanel  value={value} index={0}>
          <MyTags />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TagDocsPage overWriteDocuments={overWriteDocuments} selectedDocuments={selectedDocuments} removeDocument={removeDocument} 
          setSelectedDocuments={selectDocuments} taggedDocsGrouped={taggedDocs} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentPage setViewParsedDocument={setViewParsedDocument} viewParsedDocument={viewParsedDocument} selectedDocuments={selectedDocuments} removeDocument={removeDocument} clearDocuments={clearDocuments}/>
        </TabPanel>
    </Box>
    </Container>
  );
}

export default TagsPage
import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import getSavedSearches from "./actions/getSavedSearches";
import removeSavedSearch from "../components/actions/removeSavedSearch";
import Box from '@mui/material/Box';
import ConfirmDialogue from "../components/ConfirmDialogue";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import "./SavedSearchesPage.css"
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';

const dateFormat = localStorage.getItem('dateFormat')

const renderDate = (datetimeString) => {
  const momentObj = moment(datetimeString);
  return momentObj.format(dateFormat);;
}

const SavedSearchesPage = () => {
  const [searchPresets, setSearchPresets] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // You can change the default value as needed
  const [filterName, setFilterName] = useState('')
  const [paginatedData, setPaginatedData] = useState([])
  const [originalData, setOriginalData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing the number of rows
  };
  
  const handleClose = () => {
    setOpenConfirm(false);
  };

  useEffect(() => {
    getSavedSearches().then((response) => {
      setSearchPresets(response.data)
      setOriginalData(response.data)
    })
    
  }, [])

  useEffect(() => {
    const filtered = searchPresets.filter((preset) => preset?.preset_name?.toLowerCase().startsWith(filterName?.toLowerCase()))
    if (!filterName) {
      setSearchPresets(originalData)
    } else {
      setSearchPresets(filtered)
    }
  }, [filterName])

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = searchPresets.slice(startIndex, endIndex);
    setPaginatedData(paginatedData);
  }, [page, rowsPerPage, searchPresets]);

  const renderKey = (key) => {
    // camelCase to Capitalized Case
    const result = key.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  const isDate = (str) => {
    // checks if string is a date to trim time later
    const pattern = /^\d{4}.\d{2}.\d{2}T/;
    return pattern.test(str);
  };

  const renderFilter = (preset) => {
    // only leave the fields that are filled in
    const filledValues = Object.entries(preset).filter(([key, value]) => value)
    const filteredData = filledValues.filter(([_, v]) => !Array.isArray(v)||(Array.isArray(v) && v.length > 0));
    const filtersObject = filteredData.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    if (filtersObject['sliderValue'] == 70) {delete filtersObject['sliderValue']}
    if (!filtersObject['docSize']) {delete filtersObject['docSizeOperator']}
    return Object.entries(filtersObject)
  }

  const removePreset = () => {
    removeSavedSearch(id).then((response) => {
      if (response.status === 200) {
        const newSearchPresets = searchPresets.filter((preset) => preset.id !== id)
        setSearchPresets(newSearchPresets)
      }
    })
    setConfirm(false)
    handleClose();
  }

  const onRemovePreset = (e, id) => {
    e.stopPropagation()
    setId(id);
    setOpenConfirm(true);
  }

  const goToSearchPreset = (id) => {
    const preset = searchPresets.find((preset) => preset.id === id)
    const queryFilters = renderFilter(preset.search_filter_query)
    const qFilters = Object.fromEntries(queryFilters)
    const queryParamsArray = [];

    for (const key in qFilters) {
      if (Array.isArray(qFilters[key])) {
        const keyWithIn = `${key}__in`;
        const joinedValue = qFilters[key].join('__');
        queryParamsArray.push(`${keyWithIn}=${joinedValue}`);
      } else {
        let value
        if (isDate(qFilters[key])) {
          value = qFilters[key].split('T')[0]
        }
        else {
          value = qFilters[key]
        }
        queryParamsArray.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
    queryParamsArray.push('loadState=true') // to load the state from the url
    const queryParams = queryParamsArray.join('&');
    navigate(`/?${queryParams}`);
  }

  const totalPages = Math.ceil(searchPresets.length / rowsPerPage);

  const tablePaginationTheme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          actions: ({ ownerState, theme }) => ({
            ...(ownerState && {
              '& .MuiTablePagination-actions': {
                display: 'none',
              },
            }),
          }),
          root: {
            border: '1px solid #d2d2d2',
            maxWidth: 'fit-content',
            borderRadius: '6px',
            backgroundColor: '#fafafa',
            margin: 'auto',
            display: 'flex',
          },
        },
      },
    },
  });

  const tablePaginationNoArrowTheme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
            actions:{
              visibility: 'hidden'
            },
            root: {
              border: '1px solid #d2d2d2',
              maxWidth: 'fit-content',
              borderRadius: '6px',
              backgroundColor: '#fafafa',
              margin: 'auto',
            },
          },
          },
        },
      });


  return(
    <Container maxWidth="xl"> 
      <h2 style={{textAlign: 'center', marginTop: '14px'}} className="header-name">Saved search presets</h2>
      <div class="row" style={{display: 'flex', alignItems: 'end'}}>
        <div style={{flex: 1}} class="column">
        <TextField
          sx={{ ml: 5}}
          id="outlined-basic"
          label="Find preset by name"
          variant="outlined"
          style={{width: '400px', marginRight: '8px', marginTop: '16px'}}
          onChange={(e) => setFilterName(e.target.value)}
        />
        </div>
      <div style={{flex: 1}}  class="column">
      <ThemeProvider theme={ totalPages>1 ? tablePaginationTheme : tablePaginationNoArrowTheme}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchPresets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{mr: 5, p: 1}}
        />
      </ThemeProvider>
        </div>
      </div>
      <Box 
       sx={{ p: 3, display: 'flex', flexDirection: 'column',
          border: '1px solid #d2d2d2', m: 5, borderRadius: '6px'
      }}> 
        <ConfirmDialogue open={openConfirm} handleClose={handleClose} confirm={setConfirm}
          action={removePreset}
         />
       
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <p className="prof-item-header">#</p>
            <p className="prof-item-header">Preset name</p>
            <p className="prof-item-header">Filters</p>
            <p className="prof-item-header ml-a">Date created</p>
            <p className="prof-item-header ml-a">Actions</p>

        </Stack>
        {searchPresets?.length ? paginatedData?.map((preset, ind) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            onClick={() => goToSearchPreset(preset.id)}
            sx={{ borderBottom: 1, borderColor: 'divider',
            '&:hover': {
              backgroundColor: 'aliceblue', // Change the background color on hover
              cursor: 'pointer', // Show a pointer cursor on hover
            }, }}
          >
            <p className="prof-item">{ind+1+rowsPerPage*page}.</p>
            <p className="prof-item" style={{fontWeight: 500}}>
              {preset.preset_name}
              </p> 

            <p className="prof-item">{renderFilter(preset.search_filter_query).map(
              ([key, value]) => {
                if (Array.isArray(value)) {
                  return <p>{renderKey(key)}: <span className="filter-value">{value.join(', ')}</span> </p>
                } else {
                  return <p> {renderKey(key)}: <span className="filter-value">{isDate(value) ? renderDate(value) : value} </span></p>
                }
              }
            )}</p>
           
            <p className="prof-item ml-a">{renderDate(preset.created)}</p>
            <p className="prof-item" onClick={(e) => onRemovePreset(e, preset.id)} style={{textDecoration: 'underline', color:'#ab2626', cursor: 'pointer'}} >Remove</p>
          </Stack>
        )
        )
        : filterName ? <p> No saved presets with this name </p> :
         <p> No saved searches yet </p>
      }
      </Box>
    </Container>
    )
}

export default SavedSearchesPage;
import axios from "axios";

export function resetPassword(token, password) {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API_HOST}/api/v1/password-reset/confirm/`;
    axios.post(url, {
      token: token,
      password: password,
    })
    .then((response) => {
      resolve(response);
    }
    )
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}

export function requestResetPassword(email) {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API_HOST}/api/v1/password-reset/`;
    axios.post(url, {
      'email': email,
    })
    .then((response) => {
      resolve(response);
    }
    )
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}

    
import axios from 'axios';

const createProfileFromPromo = async (promocode) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/register/promo/`, 
    {promo_code: promocode},
    {headers: {
      'Content-Type': 'application/json',
    }})
    ;
    return response;
  } catch (error) {
    return error.response;
  }
};

export default createProfileFromPromo;
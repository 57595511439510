import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Selector from '../components/searchpage/Selector'
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import addNewTagRequest from './actions/addNewTag';
import fetchUserTags from './actions/fetchUserTags';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


function MyTags() {
  const dateFormat = localStorage.getItem('dateFormat')
  const [tagList, setTagList] = useState([])

  const updateTags = () => {
    fetchUserTags().then((response) => {
      const tagListFormatted = response.data.map((tag) => {
        tag.created = moment(tag.created).format(`${dateFormat? dateFormat : 'DD/MM/YYYY'} HH:mm`)
        return tag
      })
      setTagList(tagListFormatted)
    })
  }
  
  useEffect(() => {
    updateTags()
  }, [])


  const columns = [
    { field: 'id', headerName: 'ID', width: 110 },
    { field: 'tag', headerName: 'Tag name', width: 330 },
    { field: 'color', headerName: 'Color', width: 150 },
    { field: 'created', headerName: 'Created', width: 220 },
    { field: 'tagged_documents', headerName: 'Documents tagged', width: 140 },
  ];

  const options =[{'value': 'grey', 'label': <Chip label="grey" color="default" />},
                  {'value': 'blue', 'label': <Chip label="blue" color="info" />},
                  {'value': 'violet', 'label': <Chip label="violet" color="secondary" />},
                  {'value': 'green', 'label': <Chip label="green" color="success" />},
                  {'value': 'orange', 'label': <Chip label="orange" color="warning" />},
                  {'value': 'red', 'label': <Chip label="red" color="error" />}
                               ]
const [selectorValue, setSelectorValue] = React.useState('grey');
const [disableAdd, setDisableAdd] = React.useState(true);
const onSelectColor = (value) => {
  setSelectorValue(value)
}
const [newTag, setNewTag] = React.useState('')

const onChangeTag = (e) => {
  setNewTag(e.target.value)
  setDisableAdd(false)
}

const handleAddTag = () => {
  if (!newTag) return
  setDisableAdd(true)
  addNewTagRequest(newTag, selectorValue).then((response) => {
    if (response.status === 201) {
      updateTags()
    }
  })
}

  return (
    <Box>
      <Typography variant="h6"  gutterBottom>
        My Tags
      </Typography>
      <div id="my-tags-page" style={{padding: '2rem 0'}}>
        <FormControl sx={{display: 'inline-block'}}>
          <TextField value={newTag} onChange={onChangeTag} required id="outlined-basic" label="Tag name" variant="outlined" style={{width: '35vw', marginRight: '8px'}} />
          <Selector options={options} value={selectorValue} onChange={(e) => onSelectColor(e.target.value)}  />
          <Button style={{margin: '0 15px', height: '52px'}} disabled={disableAdd || !newTag} onClick={() => handleAddTag()} variant="contained" endIcon={<AddIcon style={{marginLeft: '8px'}}/>}>
            create
          </Button>
        </FormControl>
      </div>

      <div >
        <DataGrid
          rows={tagList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10},
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          checkboxSelection
          editMode='row'
        />
    </div>

    </Box>
  )
}
export default MyTags


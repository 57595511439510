import React, { useEffect, useState, useContext } from 'react';
import SearchPage from '../components/searchpage/SearchPage';
import MainContainer from '../components/MainContainer';
import Container from '@mui/material/Container';
import ProfilePage from '../profile/ProfilePage';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PasswordReset from '../auth/PasswordReset';
import PasswordChange from '../auth/PasswordChange';
import SavedSearchesPage from '../profile/SavedSearchesPage';
import TagsPage from '../tags/TagsPage';

function MainPage() { 

    return (
      <main>
        <Container className='gradient-bckg' maxWidth="xl">
          <MainContainer>
            <Routes>
              <Route path="*" element={<SearchPage />} />
            </Routes>
          </MainContainer >
        </Container>
        <Container maxWidth="xl" style={{padding: 0,  border: '3px solid white'}}>
            <Routes>
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/searches" element={<SavedSearchesPage />} />
              <Route path="/password-reset-confirm" element={<PasswordChange />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/tags" element={<TagsPage />} />
            </Routes>
        </Container>
      </main>
    );
}

export default MainPage;
import React, { useState } from "react";
import changePassword from "./actions/changePassword";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function ChangePasswordForm() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      setSuccess("");
      return;
    }

    const token = new URLSearchParams(window.location.search).get("token");
    changePassword({ password: newPassword, token: token }).then(
      (response) => {
        console.log(response)
        if (response.status === 200) {
          setSuccess("Password changed successfully. Redirecting to login in 3 seconds");
          setError("");
          setNewPassword("");
          setConfirmNewPassword("");
          // on countdown, redirect to login
          setTimeout(() => {
            localStorage.removeItem("token");
            window.location.href = "/login";
          }, 3000);

        }
        else {
          console.log(response)
          setError("Error changing password: " + response?.data?.password[0] || "Unknown error");
          setSuccess("");
        }
      }
    ).catch(error => {
      console.log(error)
      setError("Error changing password: " + error?.data?.password[0] || "Unknown error");
      setSuccess("");
    });
  };

  return (
    <Container maxWidth="sm" className="p-2">
      <Typography variant="h5" className="p-2" gutterBottom>
        Change Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          type="password"
          label="New Password"
          variant="outlined"
          fullWidth
          style={{ margin: 8 }}
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <TextField
          type="password"
          label="Confirm New Password"
          variant="outlined"
          fullWidth
          style={{ margin: 8 }}
          value={confirmNewPassword}
          onChange={(event) => setConfirmNewPassword(event.target.value)}
        />
        {error && (
          <div className="pt-2 pl-2">
            <Typography color="textPrimary">
              {error}
            </Typography>
          </div>
          )}
        {success && (
          <div>
            <Typography variant="body2" color="textSecondary">
              {success}
            </Typography>
          </div>
        )}
        <Button  style={{ margin: 8, padding: 8, marginTop: 20 }} type="submit" variant="contained" color="primary">
          Change Password
        </Button>
      </form>
     
     
    </Container>
  );
}

export default ChangePasswordForm;

import "./SearchStyles.css"
import { useSearchResultsContext } from '../context/SearchResultsContext';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import Tooltip from '@mui/material/Tooltip';


function SearchHeader(props) {
  const {data} = useSearchResultsContext()
  const {isLoading} = props
  // console.log('data', data)
  return (
    <div className='srh'>
      {isLoading? <p className='srh-found'>Searching...</p> : 
      <p className='srh-found'>  Found {data?.meta?.too_many_results? 'over' : ''} {data?.count ? data?.count : 'no' } matching documents </p> 
      }
      {data?.meta?.too_many_results?
       <Tooltip placement="bottom" title="Refining your search query, such as increasing search proximity or filtering extra metadata might bring more relevant results"> 
        <p style={{marginBottom: '0.2rem', width: 'fit-content'}} className='srh-found'>
          <PriorityHighRoundedIcon style={{color: '#c78100', marginLeft: '-1.55rem'}}/>
        Too many results.
        </p> 
        </Tooltip>
      : null}
    </div>
  )
}



export default SearchHeader

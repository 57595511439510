import axios from "axios";

export function registerNewUser(data) {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API_HOST}/api/v1/register/`;
    axios.post(url, {
      first_name: data.name,
      last_name: data.surname,
      email: data.email,
      password: data.password,
    })
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}

    
import Button from "@mui/material/Button"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@mui/material/TextField"
import Modal from '@mui/material/Modal';
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core';
import {resetPassword} from "./actions/resetPassword";
import {useNavigate} from "react-router-dom"


const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display: 'contents',
  }
}));



const PasswordChange = (props) => {
  const [password, setPassword] = useState('')
  const {showNotificaton, setShowNotfication, setMessage} = props
  const [badPassword, setBadPassword] = useState(false)
  const classes = useStyles();
  const navigate = useNavigate()
  // const [open, setOpen] = React.useState(true);

  const closeModal = () => {
    props.handleClose()
    navigate('/')
  };

  const handleCloseNotification = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowNotfication(false);
  }; 


  const handleSubmit = (e) => {
    e.preventDefault()
    let params = new URLSearchParams(window.location.search);
    let token = params.get('token')
    resetPassword(token, password).then(res => {
      setMessage('Your password was changed.')
      setShowNotfication(true)
      closeModal()
    }).catch(error => {
      if (error.response.status === 400) {
        setBadPassword(true)
      }
    })
  }

  
  return (
    <Modal open={props.open} onClose={closeModal} style={{ top: '20%'}}
     aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"> 
      <Card className="container mx-auto w-3/6">
        <CardContent className="grid gap-6">
          <form className={classes.form} onSubmit={handleSubmit}> 
            <div className="space-y-1 pt-5">
              <h2 className="text-2xl">Set new password</h2>
            </div>
            <div className="grid gap-4">
              <TextField id="password" autoComplete="current-password" type="password" placeholder="New Password" className="w-4/5" 
                value={password} onChange={e => setPassword(e.target.value)}
              />
            </div>
           {badPassword? 
            <div className="grid gap-4">
              <p className="text-red-500">Password must be at least 6 characters long and must not be too common</p>
              </div>
              : null}
            <div className="grid place-content-center mt-0 mb-5">
              <Button type="submit" variant="contained" color="primary" className="w-48 h-12">Change password</Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </Modal>
  )
}
export default PasswordChange 
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialogue(props) {
  const {open, handleClose, action} = props;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete this preset?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => action()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import axios from 'axios';

const changePassword = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/password-reset/confirm/`, data,
    {headers: {
      'Content-Type': 'application/json',
    }}
    )
    ;
    return response;
  } catch (error) {
    return error.response;
  }
};

export default changePassword;

import axios from "axios";
import { useLocalStorage } from "./useLocalStorage";

export const useAuth = () => {
    
  const [token, setToken] = useLocalStorage('token', null)
  const [user, setUser] = useLocalStorage('user', null)
  const [profileId, setProfileId] = useLocalStorage('profileId', null)

  const signIn = (token, user)=> {
      try {
          setToken(token);
          setUser(user['user']['id']);
          setProfileId(user['id']);
      } catch (err) {
          console.error(err);
      }
  };

  const signUp = async (data) => {
      try {
          let authresult = await axios.post('/api/auth/signup', data);
          let userObj = { ...authresult.data?.createdUser };
          userObj.token = authresult.data?.encodedToken;
          setUser(userObj);
      } catch (err) {
          console.error(err);
      }
  };

  const signOut = () => {
    setUser(null);
    setToken(null);
    setProfileId(null);
  };

  return { user, signIn, signUp, signOut };
};
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import deleteTagFromDoc from './actions/deleteTagFromDoc';
import RenderTag from '../components/RenderTag';

function TagDocsPage(props) {
  const {taggedDocsGrouped, setSelectedDocuments, selectedDocuments, removeDocument,
    overWriteDocuments} = props
  const [filtered, setFiltered] = useState(taggedDocsGrouped)
  const docInSelected = (id) => {
    return selectedDocuments.includes(id)
  }

  useEffect(() => {
    setFiltered(taggedDocsGrouped)
  }, [taggedDocsGrouped])
  

  const selectAllByTag = (tagId) => {
    const docs = taggedDocsGrouped.find((group) => group.tag.id == tagId).documents
    setSelectedDocuments([...selectedDocuments, ...docs.map((doc) => doc.id)])
  }
  
  const unselectAllByTag = (tagId) => {
    const docs = taggedDocsGrouped.find((group) => group.tag.id === tagId).documents;
    const idsToRemove = docs.map((doc) => doc.id);
    overWriteDocuments((prevSelectedDocuments) => prevSelectedDocuments.filter((doc) => !idsToRemove.includes(doc)));
  }

  const alreadySelected = (tagId) => {
    const docs = taggedDocsGrouped.find((group) => group.tag.id == tagId).documents
    return docs.every((doc) => selectedDocuments.includes(doc.id))
  }

  const deleteTaggedDoc = (taggedDocId) => {
    deleteTagFromDoc(taggedDocId).then((response) => {
      if (response.status == 204){
        const filtered = (taggedDocsGrouped.map((obj) => ({
          tag: obj.tag,
          documents: obj.documents.filter((doc) => doc.case_tag_id !== taggedDocId),
        })))
        setFiltered(filtered)
      }
    })
  }


  return (
    <Box>
      <Typography variant="h6"  gutterBottom>
        Documents tagged
      </Typography>
      <div style={{ padding: '2rem 0' }} className='docs-tagged-page'>

        {filtered?.map((group) => {
          return (
            <div key={group?.tag?.id} style={{ padding: '2rem 0'}}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RenderTag tag={group?.tag} />
                <Button onClick={() => !alreadySelected(group?.tag?.id) ? selectAllByTag(group?.tag?.id) : unselectAllByTag(group?.tag?.id)}
                > {!alreadySelected(group?.tag?.id) ? 'Add all to view' : 'Remove all from view'} </Button>
               </div>
               <Divider variant="inset"  />
              <div style={{ padding: '1rem 0' }}>
                {group?.documents?.map((doc, i) => {
                  return (
                    <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                        <DeleteIcon onClick={() => deleteTaggedDoc(doc.case_tag_id)}/>
                      </IconButton>
                    }
                  >
                    <p onClick={() => docInSelected(doc.id) ? removeDocument(doc.id) : setSelectedDocuments(doc.id)}
                     style={{padding: '4px 0', fontSize: '18px', cursor: 'pointer',
                     textDecoration: docInSelected(doc.id) ? 'underline' : ''}} key={doc.document_identifier}>
                      {i+1}.  {doc.document_name} </p>
                    </ListItem>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>


    </Box>
  )
}
export default TagDocsPage

import React from 'react'
import "./ParsedDocument.css"
import { Button, Typography } from '@mui/material'
import Fab from '@mui/material/Fab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ParsedDocument = ({document, title, matchedParagraphs}) => {
  const [show, setShow] = React.useState('')
  const [highlightMatches, setHighlightMatches] = React.useState(true)
  const scrollToRef = React.useRef(null);
  const sortedMatchedParagraphs = matchedParagraphs?.sort((a, b) => a - b)

  const scrollToElement = () => {
    scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  

  // const createRef = () => {
  //   const newRef = useRef(null);
  //   myRefs.push(newRef);
  // }


  return (
    <> 
    {matchedParagraphs ?
      <div style={{display: 'flex', justifyContent: 'right'}}>
        <Button variant={highlightMatches? 'contained' : 'outlined'} onClick={() => setHighlightMatches(!highlightMatches)}>{highlightMatches? 'un': ''}highlight matches </Button>
      </div>
    : null}
    <Typography variant='h5' sx={{ mt: 3, textAlign: 'center'}}> {title} </Typography>
    {matchedParagraphs ? 
    <div className='sticky-container' style={{position: 'sticky', display: 'flex', justifyContent: 'right', marginRight: '12px', top: '25%'}}> 
      <Fab onClick={() => scrollToElement()} color="grey" size="small" 
      style={{ opacity: '55%'}} 
      aria-label="add">
        <KeyboardArrowDownIcon variant="outlined" />
      </Fab>
    </div> : null}
    <div className='parsed-text'>
      {
        document?.map(paragraph => {
          return (
            <p ref={sortedMatchedParagraphs?.[0] == paragraph.paragraph_number ? scrollToRef : null}
             onMouseEnter={() => setShow(paragraph.paragraph_number)} onMouseLeave={() => setShow('')}
              style={matchedParagraphs?.includes(paragraph.paragraph_number) && highlightMatches ? {backgroundColor: '#fff5ce'}: {}}
            >
              <span style={show == paragraph.paragraph_number? {display: 'inline', position: 'relative', left: '-3rem'}: 
                {display: 'inline', position: 'relative', left: '-2rem', opacity: 0}} className='helper-text'>
                ¶{paragraph.paragraph_number}
              </span>
              {paragraph.text}</p>
          )
        })
      }
    </div>
    </>
  )
}
export default ParsedDocument
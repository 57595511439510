import React from 'react'
import Chip from '@mui/material/Chip';

const renderColor = (color) => {
  switch (color) {
    case 'red':
      return 'error'
    case 'blue':
      return 'primary'
    case 'green':
      return 'success'
    case 'violet':
      return 'secondary'
    case 'orange':
      return 'warning'
    default:
      return 'default'
  }
}


const RenderTag = ({tag}) => {
  return (
    <Chip style={{cursor: 'pointer'}}  label={tag.tag} color={renderColor(tag.color)}
     sx={{m: 0.5}} />
  )
}

export default RenderTag
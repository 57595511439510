import Button from "@mui/material/Button"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@mui/material/TextField"
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core';
import {useAuthContext} from "../context/AuthContext";
import {getAuthToken} from "./actions/authenticate";
import SnackbarNotify from "../components/SnackbarNotify";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display: 'contents',
  }
}));

const SignIn = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showNotificaton, setShowNotfication] = useState(false)
  const classes = useStyles();
  const {user, signIn, signUp, signOut} = useAuthContext()
  const [wrongCredentials, setWrongCredentials] = useState(false)
  const [serverError, setServerError] = useState(false)


  const handleCloseNotification = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowNotfication(false);
  }; 

  const handleSubmit = (e) => {
    e.preventDefault()
    
    getAuthToken(email, password).then(res => {
      const token = res.data.token
      const user = res.data.user
      signIn(token, user)
      setShowNotfication(true)
      props.handleClose()
    }).catch(error => {
      if (error.response.status === 400) {
        setWrongCredentials(true)
        setServerError(false)
      }
      else if (error.response.status === 500) {
        setServerError(true)
        setWrongCredentials(false)
      }
    })
  }

  
  return (
    <Modal open={props.open} onClose={props.handleClose} style={{ top: '20%'}}
     aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"> 
      <Card className="container mx-auto w-3/6">
        <CardContent className="grid gap-6">
          <form className={classes.form} onSubmit={handleSubmit}> 
            <div className="space-y-1 pt-5">
              <h2 className="text-2xl">Log into account</h2>
            </div>
            <div className="grid gap-4">
              <TextField id="email" type="email" placeholder="Email Address" className="w-4/5" autoComplete="email"
                value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className="grid gap-4">
              <TextField id="password" autoComplete="current-password" type="password" placeholder="Password" className="w-4/5" 
                value={password} onChange={e => setPassword(e.target.value)}
              />
            </div>
            {wrongCredentials ?
              <div className="grid gap-4">
                <p className="text-red-500">Email or password are invalid</p>
              </div>  : ''
            }
            {serverError ?
              <div className="grid gap-4">
                <p className="text-red-500">Something went wrong. Please try again later</p>
              </div>  : ''
            }
            <div>
              <Link href="password-reset"  variant="body2">
                Forgot password?
              </Link>
            </div>
            <div className="grid place-content-center mt-0 mb-5">
              <Button type="submit" variant="contained" color="primary" className="w-48 h-12">Sign In</Button>
            </div>
          </form>
        <SnackbarNotify open={showNotificaton} message={'logged in'} handleClose={handleCloseNotification} />
        </CardContent>
      </Card>
    </Modal>
  )
}
export default SignIn 
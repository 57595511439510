import Button from "@mui/material/Button"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@mui/material/TextField"
import Modal from '@mui/material/Modal';
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core';
import {requestResetPassword} from "./actions/resetPassword";
import {useNavigate} from "react-router-dom"


const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display: 'contents',
  }
}));

const PasswordReset = (props) => {
  const [email, setEmail] = useState('')
  const {showNotificaton, setShowNotfication, setMessage} = props
  const [badPassword, setBadPassword] = useState(false)
  const classes = useStyles();
  const navigate = useNavigate()
  // const [open, setOpen] = React.useState(true);
  const [sent, setSent] = useState(false)

  const closeModal = () => {
    navigate('/profile')
    props.handleClose()
  };

  const handleCloseNotification = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowNotfication(false);
  }; 

  const handleSubmit = (e) => {
    e.preventDefault()
    requestResetPassword(email).then(res => {
      setSent(true)
      setMessage('Reset password request has been sent to your email.')
      setShowNotfication(true)
      closeModal()
      
    }).catch(error => {
      if (error.response.status === 400) {
        setBadPassword(true)
      }
    })
  }

  
  return (
    <Modal open={props.open} style={{ top: '20%'}} onClose={closeModal}
     aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"> 
      <Card className="container mx-auto w-3/6">
        <CardContent className="grid gap-6">
          <form className={classes.form} onSubmit={handleSubmit}> 
            <div className="space-y-1 pt-5">
              <h2 className="text-2xl">Reset password</h2>
            </div>
            <div className="grid gap-4">
              <TextField id="email" type="email" placeholder="Email Address" className="w-4/5" autoComplete="email"
                value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
           {badPassword? 
            <div className="grid gap-4">
              <p className="text-red-500">Can't find email</p>
              </div>
              : null}
            <div className="grid place-content-center mt-0 mb-5">
              <Button disabled={sent} type="submit" variant="contained" color="primary" className="w-48 h-12">Change password</Button>
            </div>
          </form>
      
        </CardContent>
      </Card>
    </Modal>
  )
}
export default PasswordReset 
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import RenderTag from '../components/RenderTag';
import ParsedDocumentPage from './ParsedDocumentPage';
import Dialog from '@mui/material/Dialog';


const DocumentCard = ({ data, remove, clear, userTags, openDialog, _setViewParsedDocument, setTab, viewParsedDocument }) => {
  const [showParsedDocument, setShowParsedDocument] = React.useState(false)

  const onViewParsedDocument = (docId) => {
    _setViewParsedDocument(docId)
    // change query params
    const url = new URL(window.location.href);
    let cur_tab = url.searchParams.get("tab")
    if (cur_tab != 'parsed') {
      url.searchParams.set("tab", "parsed");
      window.history.pushState({}, '', url);
      if (setTab) {
        setTab(2)
      }}
    if (!setTab) {
      setShowParsedDocument(true)
    }
  }

  return (
    <Card sx={{ minWidth: 275, p: 2 }}>
      <CardContent>
        <div id="bookmark" style={{position: 'relative', float:'right'}}>
          <Tooltip title="Set tag" placement="left">
            <IconButton id="tag-doc" onClick={() => openDialog(data)}
            >  <BookmarksIcon sx={{ width: 30, height: 30 }}/> </IconButton>
          </Tooltip> 
        </div>
        <Typography variant="h6" style={{margin: '0 0 1rem -0.65rem'}} 
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
          <div>
            {data?.document_title_text}
          </div>
          <div style={{marginRight: '1rem'}}> 
            {data.tag?.map((tag) => {
                return (
                  <RenderTag tag={tag.tag} />
                )
              })}
          </div>
        </Typography>
        {showParsedDocument ? 
          <Dialog open={showParsedDocument}
           onClose={() => setShowParsedDocument(false)}  maxWidth="xl" fullWidth>
            <ParsedDocumentPage parsedDocumentMeta={data}/> 
          </Dialog>
          : 
        null}
        <Grid id="metadata-docs" container spacing={2}>
          <Grid item xs={5}>
            <Typography variant="body1" color="text.primary">
              Document Category: {data?.document_category}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Proceeding Number: {data?.proceeding_number}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Filing Party Category: {data?.filing_party_category}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Document Filing Date: {data?.document_filing_date}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Document Type Name: {data?.document_type_name}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Proceeding Type Category: {data?.proceeding_type_category}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Subproceeding Type Category: {data?.subproceeding_type_category}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Document Size: {data?.document_size ? (data?.document_size / 1024).toFixed(0) : 0} Kb
            </Typography>
            <Typography variant="body1" color="text.primary">
              Document number: {data?.document_number}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Document Identifier: {data?.document_identifier}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="text.primary">
              Object UUID: {data?.object_uuid}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Respondent Technology Center Number: {data?.respondent_technology_center_number}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Respondent Group Art Unit Number: {data?.respondent_group_art_unit_number}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Respondent Inventor Name: {data?.respondent_inventor_name}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Respondent Grant Date: {data?.respondent_grant_date}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Respondent Patent Number: {data?.respondent_patent_number}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Respondent Application Number Text: {data?.respondent_application_number_text}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Petitioner Party Name: {data?.petitioner_party_name}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Petitioner Counsel Name: {data?.petitioner_counsel_name}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Document page count: {data?.processed_metadata?.pages_count}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Tooltip title="Download" placement="left" sx={{p: 0, my: 3}} >
                <IconButton 
                  onClick={() => window.open(data?.document_url ? data.document_url : `https://developer.uspto.gov/ptab-api/documents/${data.document_identifier}/download`, "_blank")}
                > <DownloadIcon sx={{ width: 40, height: 40}}/> </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Remove" placement="left"  sx={{p: 0, my: 3}}>     
                  <IconButton sx={{ width: 40, height: 40 }}
                    onClick={() => remove(data.id)}
                  >  <RemoveIcon sx={{ width: 40, height: 40 }}/> 
                  </IconButton>
                </Tooltip>  
              </Grid>
              <Grid item>
                <div id="inspectparsed"> 
                <Tooltip title="View document text" placement="left">    
                  <IconButton sx={{ width: 44, height: 44, my: 2 }} disabled={data?.processed_metadata?.paragraphs_count < 2}
                    onClick={() => onViewParsedDocument(data)}
                  >  <DescriptionIcon sx={{ width: 32, height: 32 }}/> 
                  </IconButton>  
                </Tooltip>  
                </div>
              </Grid>
          </Grid>
          </Grid>
        </Grid>
        
        {/* Add more fields as needed */}
      </CardContent>
    </Card>
  
    );

};

export default DocumentCard;

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

export default function SnackbarNotify(props) {

  const {open, setOpen, message, handleClose, duration, severity} = props;


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={duration? duration: 6000}
        onClose={handleClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal : 'right' }}
      >
        {severity ? 
        <Alert onClose={handleClose} severity={severity}>
           {message}
        </Alert> : null
      }
        </Snackbar>
    </div>
  );
}

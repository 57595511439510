import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectFilter(props) {
  const {options, labels, value, handleChange, label, width} = props; 

  return (
    <Box sx={{ minWidth: !width ? 120 : width, maxWidth: !width ? null : width }}>
      <FormControl fullWidth>
        <InputLabel id={`simple-select-label-${label}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${label}`}
          id={`simple-select-label-${label}`}
          value={value}
          label={label}
          onChange={handleChange}
          sx={{
            "& div": { backgroundColor: '#fff' },
          }}
        >
          {options.map((option, i) => {
            return (
              <MenuItem value={option}>{labels[i]}</MenuItem>
            )})}
        </Select>
      </FormControl>
    </Box>
  );
}


import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './SearchStyles.css'
import SnackbarNotify from "../components/SnackbarNotify";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import { useSearchResultsContext } from '../context/SearchResultsContext';

const ParagraphResults = (props) => {
  const {paragraphData} = props
  const paragraphs = paragraphData?.hits
  const count = paragraphData?.count
  const distance = paragraphData?.average_distance
  const [showParagraphs, setShowParagraphs] = React.useState(false)
  const {showAllParagraphs, onViewParsedDocument, docId} = props

  return (
    <div className="paragraph-container">
      <p className="helper-text" onClick={() => setShowParagraphs(!showParagraphs)}
      style={{padding: '2px 0 8px 0', marginTop: '-6px', fontSize: '14px', color:'#6d768b', cursor: 'pointer'}}> 
      matched paragraphs:  {count}</p>
      {showParagraphs || showAllParagraphs ?
        <div className='par-res-container'>
          {paragraphs?.map((paragraph) => {
            return (
              <div onClick={() => onViewParsedDocument(docId)} key={paragraph.id} style={{display: 'flex', cursor: 'pointer',
              maxWidth: '94%', padding: '0 8px', marginBottom: '0.4rem'}}> 
                <span style={{ fontSize: '14px', minWidth: '2.3rem'}} className='helper-text'>
                    ¶{paragraph.paragraphNumber}
                  </span>
                <p className="paragraph-text">
                  {paragraph.text}</p>
              </div>
            )
          })}
        </div>
        : null}
    </div>
  )
}


const Case = (props) => {
  const {caseName, caseItems, ind, currentPage} = props
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('success');
  const [caseAdded, setCaseAdded] = React.useState(false)
  const [addedDocuments, setAddedDocuments] = React.useState([])
  const [showAllParagraphs, setShowAllParagraphs] = React.useState(false)
  const [showParsedDocument, setShowParsedDocument] = React.useState(false)
  const {run, setRun, tourStep, setTourStep} = useSearchResultsContext() || {};

  const onViewParsedDocument = (docId) => {
    console.log(docId)
    props.setViewParsedDocument(docId)
    // change query params
    const url = new URL(window.location.href);
    let cur_tab = url.searchParams.get("tab")
    if (cur_tab != 'parsed') {
      url.searchParams.set("tab", "parsed");
      window.history.pushState({}, '', url);
      props.setTab(2)
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onDocumentSelect = (doc) => {
    if (!addedDocuments.includes(doc.id)) {
      props.selectDocuments(doc.id)
      if (doc?.paragraphs?.hits?.length) {
        const matchedParagraphs = doc.paragraphs.hits.map((paragraph) => paragraph.paragraphNumber)
        props.setMatchedParagraphs(doc.id, matchedParagraphs)
      }
      setAddedDocuments([...addedDocuments, doc.id])
      setSeverity('success')
      setMessage(`Document ${doc.document_name} added to the view`)
      setOpen(true)
    } else {
      setSeverity('warning')
      setMessage(`Document ${doc.document_name} already in the view`)
      setOpen(true)
    }
  }

  const onWholeCaseSelect = (selectedCase) => {
    const toAdd = selectedCase.map((item) => item.id)
    props.selectDocuments(toAdd)
    setSeverity('success')
    setMessage(`All documents in ${caseName} added to the view`)
    setOpen(true)
    setAddedDocuments([...addedDocuments, ...toAdd])
    setCaseAdded(true)
    for (let doc of selectedCase) {
      if (doc?.paragraphs?.hits?.length) {
        const matchedParagraphs = doc.paragraphs.hits.map((paragraph) => paragraph.paragraphNumber)
        props.setMatchedParagraphs(doc.id, matchedParagraphs)
      }
    }
  }

  // console.log('caseItems', caseItems)
  const fileNames = caseItems?.map((item) => item.document_name)
  const paragraphs = caseItems?.map((item) => item?.paragraphs).filter((item) => item)
  // console.log(paragraphs)
  const perPage = 30
  const start = (currentPage-1) * perPage

  const moveTourNext = () => {
    if (run && tourStep == 4) {
      setTourStep(5)
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={`panel1a-header-${ind}`}
        sx={{ display: 'flex', }}
        onClick={moveTourNext} // for tour
      >
       <p className="als-ctr">{start+ind+1}.</p>
        <div className="case-container">
          <span className="f-right dg mt-lh">Transcript</span>
          <p className="case-name">{caseName}</p>  
          <p className="helper-text lh2">{caseItems.length} {caseItems.length> 1 ? "matches" : 'match'} </p>
          <div className="file-preview-container">
            { fileNames?.map((fileName, index) =>
              {
                return index < 3 ? 
                <span className="file-text "> {fileName} 
                {index != fileNames?.length-1 ? ','  : ''}</span> 
                : index == 3 ? 
                <span className="file-text ">{fileName}</span> :
                 index == 4 ? <span >...</span> : null
              }
            )}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="muted-bckg" >
        {/* {caseItems?.paragraphs?.length ? 
         caseItems?.map((file, index) => {
            return (
              <FileRes index={index} file={file} />
            )
        })
        : */}
       <SnackbarNotify duration={1800}
        handleClose={handleClose} open={open} setOpen={setOpen} message={message} severity={severity} />
        <div className='files-container'>
          <div className='buttons-container' style={{alignSelf: 'flex-end', display: 'flex', gap: '1rem', position: 'absolute'}}> 
        
          {paragraphs?.length > 1 ?
            <Button variant="outlined" size="small"  color="secondary"
              onClick={() => setShowAllParagraphs(!showAllParagraphs)} >
              Expand matches
          </Button> 
        : null}
          {fileNames?.length > 2 ?
             <Button disabled={caseAdded} variant="outlined" size="small" 
             onClick={() => onWholeCaseSelect(caseItems)} >
              Add all to view
            </Button> 
          : null}
        </div>

          {fileNames?.map((fileName, index) => {
            return (
              <div className='displ-parsed' style={!paragraphs[index]?.count ?{display: 'flex', justifyContent: 'space-between'} : {}}> 
                <Tooltip title="Add to document view list" placement="top">
                  <p style={addedDocuments.includes(caseItems[index]?.id) ? {textDecoration: 'underline'} : {} }
                  className="file-res" onClick={() => onDocumentSelect(caseItems[index])}> 
                  <span style={{marginRight: '0.6rem'}}> {index+1}.</span> 
                    {fileName} 
                  </p>
                </Tooltip>
                {!paragraphs[index]?.count ?
                <Tooltip title="View document text" placement="left">
                <span className="parsed-doc-view-btn" onClick={() => onViewParsedDocument(caseItems[index]?.document_identifier)}
                 style={index == 0 ? { marginRight:'10rem'} : {}}>
                  
                  <DescriptionIcon sx={{ width: 28, height: 28 }}/> 
                 </span> 
                 </Tooltip>
                : null}
                {paragraphs[index]?.count? 
                  <ParagraphResults onViewParsedDocument={onViewParsedDocument} docId={caseItems[index]?.document_identifier}
                   paragraphData={paragraphs[index]} showAllParagraphs={showAllParagraphs}/>
                : null
                }
              </div>
            )}
          )}
        </div>
      {/* } */}
        
      </AccordionDetails>
    </Accordion>
  )
}

export default Case
import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[1], 
  padding: theme.spacing(2), // Adjust padding as needed
}));

const StyledTextField = styled(TextField)({
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove the underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none', // Remove the underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none', // Remove the underline on focus
    },
    '& .MuiInputBase-input': {
        marginTop: '-7px', // Adjust the negative margin as needed
        marginLeft: '5px',
      },
    '& .MuiInput-root:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    } 
  });
  
const examples = ["patent light osram", "patent cancer treatment experimental"]

function SearchField(props) {
    const [search, setSearch] = React.useState('');
    const [randomExample, setRandomExample] = React.useState('')

    const setUrlParams = (paramStr, paramValue) => {
      const params = new URLSearchParams(window.location.search);
      params.set(paramStr, paramValue);
      if (paramValue === '') {
        params.delete(paramStr);
      }
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
    }
    const onSetSearch = (value) => {
      setSearch(value);
      setUrlParams('q', value);
    };

    React.useEffect(() => {
      const randomExample = examples[Math.floor(Math.random() * examples.length)];
      setRandomExample(randomExample)
      const params = new URLSearchParams(window.location.search);
      const q = params.get('q');
      if (q) {
        setSearch(q);
      }
      if (!q && search) {
        params.set('q', search);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
      }
    }, [])
    
    return (
        <Box
            sx={{
                flexWrap: 'wrap',
                '& > :not(style)': {
                m: 1,
                height: '48px',
                },
            }}
        >
          {/* <SearchIcon sx={{color: 'rgb(154, 160, 166)', mb: 3, display: 'inline'}}/> */}
        <StyledPaper elevation={1}>
            <StyledTextField
              variant='standard'
              label=""
              defaultValue=""
              fullWidth 
              autoFocus 
              placeholder={"Search: " + randomExample}
              onChange={(e) => onSetSearch(e.target.value)}
              value={search}
              className='search-bar'
              // InputProps={{
              //     startAdornment: <SearchIcon sx={{color: 'rgb(154, 160, 166)', mb: 3, display: 'inline'}}/>,
              // }}

            />
        </StyledPaper>
        </Box>
    );
}

export default SearchField;
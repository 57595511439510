import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 

export default function Selector(props) {
    return (
        <FormControl>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.value}
            label={props.label}
            onChange={props.onChange}
            style={{width: '120px',
            marginLeft: '8px',  height: '55px'
          }}
        >
            {props.options.map(option => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
            ))
            }
        </Select>
        </FormControl>
    )
};
import React, {useContext, useEffect} from 'react'
import Case from './Case'
import { useSearchResultsContext, SearchResultsContext } from '../context/SearchResultsContext';
const SearchResults = (props) => {
  // const { data } = props
  const {data, setSearchResults} = useSearchResultsContext()
  const {isLoading} = props
  const [weaviateOnly, setWeaviateOnly] = React.useState(false)
  let caseItems = data?.results ? Object.keys(data.results) : []
  
  const currentPage = data?.current_page
  // console.log('aaaaaaaaaaaaaa', data)
  useEffect(() => { // for paragraph-only
    if (!caseItems?.length && data) {
      caseItems = Object.keys(data)
      setWeaviateOnly(true)
    }
    else {
      setWeaviateOnly(false)
    }
  }, [data, caseItems])
  return (
      <div style={isLoading? {opacity: "60%"} : {}}>
          {caseItems.map((caseName, index) => {
            return (
              <Case setTab={props.setTab} setViewParsedDocument={props.setViewParsedDocument} ind={index} weaviateOnly={weaviateOnly} currentPage={currentPage} caseName={caseName} selectDocuments={props.selectDocuments}
              setMatchedParagraphs={props.setMatchedParagraphs} caseItems={data.results[caseName]} />
            )
          })
          }
      </div>

  )
}

export default SearchResults
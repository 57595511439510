import axios from 'axios';

const verifyEmail = async () => {
  try {
    const token = localStorage.getItem('token').replace(/^"(.*)"$/, '$1');
    const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/email-verification/`, {},
    {headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
    }})
    ;
    return response;
  } catch (error) {
    console.error(error);
  }
};

export default verifyEmail;

import  {
  colors,
  Avatar,
  CssBaseline,
  Typography,
  Container,
  Box,
  Grid,
  makeStyles,
  Button,
  TextField,
  Link,
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import React, {useEffect} from "react";
import { registerNewUser } from "./actions/register";

// Create a theme instance.


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));


function Copyright() {
  return (
    <Typography
      variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a>
        Xledge {' '}
      </a>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}




const Register = (props) => {
  const classes = useStyles();
  const [name, setName] = React.useState('')
  const [surname, setSurname] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [email, setEmail] =  React.useState('')
  const [validPassword, setValidPassword] = React.useState(false)
  const [validEmail, setValidEmail] = React.useState(false)
  const [showErrors, setShowErrors] = React.useState(false)

  useEffect ( () => {
    onPassword2Input()}
    , [password, password2]
  )

  useEffect ( () => {
      setValidEmail(validateEmail())
    }, [email]
  )


  function switchToLogin() {
    props.handleClose()
    props.openSignIn()
  }

  const validatePassword = () => {
    return password.length > 5
  }

  const passwordsMatch = () => {
    return password === password2
  }

  const onPassword2Input = () => {
    if (validatePassword() && passwordsMatch()) {
      setValidPassword(true)
    }
    else {
      setValidPassword(false)
    }
  }

  const allValid = () => {
    return Boolean(validEmail && validPassword && name?.length>0 && surname?.length>0)
  }

  const validateEmail = () => {
    return email.length > 5 && email.includes('@') && email.includes('.')
  }

  const onSubmitForm = (event) => {
    event.preventDefault()
    const validForm = allValid()
    if (!validForm) {
      setShowErrors(true)
      props.setSuccess(false)
      props.setMessage('Please fill in all the fields correctly.')
      props.setShowNotfication(true) 
    }
    else{ 
      registerNewUser({name, surname, email, password})
      .then((response) => {
        if (response.status === 201) {
          props.setSuccess(true)
          props.setMessage('You were successfully registered! You can now log in.')
          props.setShowNotfication(true) 
          switchToLogin()
        }
        else if (response.status === 400) {
          props.setSuccess(false)
          props.setMessage('Provided data is invalid. Please try different password.')
          props.setShowNotfication(true) 
        }
        else if (response.status === 409) {
          props.setSuccess(false)
          props.setMessage('This email is already registered.')
          props.setShowNotfication(true) 
        }
      }).catch(error => {
        console.log('error', error)
        props.setSuccess(false)
        const message = error.response?.data?.message || 'Something went wrong. Please try again later.'
        props.setMessage(message)
        props.setShowNotfication(true) 
      })
    } 
  }

  return (
    <Modal open={props.open} className={classes.paper} onClose={props.handleClose}>
      <Paper className={classes.paper} style={{padding: '0 1.5rem 2rem 1.5rem'}}>
      <Container maxWidth="xs" style={{backgroundColor: 'white'}}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component="h1" variant="h5">
            Create New Account
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={e => setName(e.target.value)}
                  value={name}
                  error={name?.length===0 && showErrors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={e => setSurname(e.target.value)}
                  value={surname}
                  error={surname?.length===0 && showErrors}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  error={!validEmail && showErrors}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  error={!validPassword && showErrors}
                  helperText="Password must be at least 6 characters long."
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password2"
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  autoComplete="current-password"
                  onChange={e => setPassword2(e.target.value)}
                  value={password2}
                  error={!passwordsMatch && showErrors}
                />
            </Grid>
            </Grid>
             
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmitForm}
            >
              Sign Up
            </Button>
            <Grid container>
              <Link href="#"  style={{margin: '0.5rem 0 1rem 0'}} onClick={switchToLogin} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      </Paper>
    </Modal>
  );
}

export default Register;
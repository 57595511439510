import React from "react";
import Grid from '@mui/material/Grid'; 
import './Footer.css';
import Box from '@mui/material/Box';

export default function Footer() {
    
    return (
        <footer className="footer">
            <Grid container py={1} px={15} justifyContent="space-between">
                <p className="footer-main">XLege © 2023 | All rights reserved</p>
                <Box display="flex">
                    <Box px={2}><a className="footer-main">Privacy Policy</a> </Box>
                    <Box px={2}><a className="footer-main">User Agreement</a></Box>
                </Box>
            </Grid>
        </footer>
    );
}
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import SearchIcon from '@mui/icons-material/Search';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export default function ProfileMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Tooltip title="My documents">
        <Link to="/tags?tab=tagged-documents" id="my-documents-nav">
          <IconButton
            size="small"
            sx={{ mr: 3, color: '#939393' }}
          >
            <DescriptionOutlinedIcon sx={{ width: 32, height: 32 }} />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>
            <AccountCircleIcon sx={{ width: 32, height: 32 }} />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Link to="/">
          <MenuItem onClick={handleClose} sx={{mb:0.5, py: 1}}>
            <ListItemIcon>
              <SearchIcon fontSize="small" />
            </ListItemIcon> Go to search
          </MenuItem>
        </Link>
        <Link to="/profile">
          <MenuItem onClick={handleClose} sx={{mb:0.5, py: 1}}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon> Profile settings 
          </MenuItem>
        </Link>

        <Link to="/searches">
          <MenuItem onClick={handleClose} sx={{my:0.5, py: 1}}>
            <ManageSearchIcon 
            sx={{ mr: 1.45, color: 'grey' }}
            >
              <Settings fontSize="small" />
            </ManageSearchIcon> Saved searches
          </MenuItem>
        </Link>
        <Link to="/tags">
          <MenuItem onClick={handleClose} sx={{my:0.5, py: 1}}>
            <TurnedInNotIcon 
            sx={{ mr: 1.45, color: 'grey' }}
            >
              <Settings fontSize="small" />
            </TurnedInNotIcon> My tags
          </MenuItem>
        </Link>
      
      <Link to="/?walkthrough=1">
        <MenuItem onClick={handleClose} sx={{my:0.5, py: 1}}
        >
          <HelpOutlineIcon 
          sx={{ mr: 1.45, color: 'grey' }}
          >
            <Settings fontSize="small" />

          </HelpOutlineIcon> Walkthrough
        </MenuItem>
        <Divider />
      </Link>
        <MenuItem onClick={props.signOut} sx={{my:0.5, py: 1}}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

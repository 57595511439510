import React, { useRef, useEffect, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import RegularCaseButton from '../buttons/RegularButton';
import '../../../src/App.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Filters from './Filters';
import LoadingButton from '@mui/lab/LoadingButton';
import saveSearchRequest from '../actions/saveSearch';
import SaveSearchDialogue from './saveSearchDialogue';
import SnackbarNotify from '../SnackbarNotify';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Tooltip } from '@mui/material';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useParams, useLocation } from 'react-router-dom';
import { useSearchResultsContext, SearchResultsContext } from '../../context/SearchResultsContext';

export default function FilterContainer(props) {
    const {performSearch, isLoading, setFilterParams} = props
    const [openSaveDialogue, setOpenSaveDialogue] = React.useState(false);
    const [presetName, setPresetName] = React.useState('');
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [showTip, setShowTip] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const {run, setRun, tourStep, setTourStep} = useSearchResultsContext() || {};
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const steps = [
      {
        title: 'Search basics',
        target: '.search-bar',
        placement: 'left',
        content: <div>
          <p className='walkstep'>If you need to search by topic or meaning, you can search for documents here.</p>
          <p className='walkstep'> Search query can be a word or a phrase, abstract or more specific.</p>
          <p className='walkstep'> In this walkthrough, we'll focus on non-semantic search.</p>
        </div>
      },
      {
        target: '.filters-r',
        disableBeacon: true,
        hideCloseButton: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        content:  <div>
            <p className='walkstep'>You can also filter documents by name, type, date and other categories. You can use filter both with, and without a search query.</p>
            <p className='walkstep' style={{paddingTop: '0.75rem'}}>  <b>Click this button </b> to open filter panel.</p>
        </div>,
      },
      {
        target: '#docid-m',
        title: 'Filtering',
        content: <div>
          <p className='walkstep'>To filter by several values, separate them with a semicolon and space.</p>
          <p className='walkstep'>The following means: filter document ids 30522574 OR 235098240 OR 989986</p>
        </div>,
      },
      {
        target: '.btn-search',
        disableBeacon: true,
        title: 'Perfoming search',
        hideCloseButton: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        content: <div>
          <p className='walkstep'>For now, let's limit our document category to exhibits. We've already checked the filter for you. </p> 
          <p className='walkstep' style={{marginTop: '1rem'}}>Click on the <b> Search</b> button to proceed</p>
        </div>
      },
      {
        target: '#panel1a-header-0',
        title: 'Exploring search results',
        spotlightClicks: true,
        disableBeacon: true,
        placement: 'top',
        hideCloseButton: true,
        content: <div>  <p className='walkstep'>Search results are divided in Cases, which are subdived in files (or documents). </p>
         <p className='walkstep'>
          Clicking on the found Case shows the documents that matched your search.</p>
          <p className='walkstep'>Now, <b> click on the first found case </b> to proceed.</p>
          </div>
      },
      {
        target: '.file-res',
        spotlightClicks: true,
        placement: 'top',
        disableBeacon: true,
        title: 'Case files',
        hideCloseButton: true,
        showSkipButton: false,
        content: <div>
          <p className='walkstep'>You've revealed the documents of the case that match your search criteria. </p>
          <p className='walkstep'>The cases which have more matching documents will be placed higher in search results.</p>
        <p className='walkstep'>Clicking on the particular file name adds it to your document view, where you can inspect its data</p>
        <p className='walkstep' style={{marginTop: '1rem'}}>Try to <b>add this particular matched file </b>!</p>
        </div>
      },
      {
        target: '#simple-tab-2',
        spotlightClicks: true,
        disableBeacon: true,
        title: 'Navigating results tab',
        hideCloseButton: true,
        content: <p className='walkstep'>After you added document,  <b> navigate to the document view tab by clicking here. </b> </p>
      },
      {
        title: 'Document view',
        target: '.document-container',
        content: <div> 
          <p className='walkstep'>You can inspect the documents metadata here.</p>
          <p className='walkstep'>Document view stores selected documents temporarily - until you close your browser (or remove doc with the button)</p>
          <p className='walkstep'>For persistent selection Xlege uses document tags, which we'll explore next.</p>
          <p className='walkstep'>On the right side panel, the control buttons are located: </p>
          <p className='walkstep'>Tag, Download, Remove from view, View document text</p>
         </div>
      },
      {
        title: 'Tagging documents',
        spotlightClicks: true,
        target: '#bookmark',
        content: <div>
         <p className='walkstep'>By clicking bookmark, you can tag the document so you can find it later in our documents.</p>
         <p className='walkstep'> A single document can have multiple different tags.</p>
         <p className='walkstep' style={{marginTop: '1rem'}}>Let's <b> click here and tag this document </b> with a precreated tag
          <span style={{fontStyle: 'italic'}}> "Saved" </span>
         </p>
         </div>
      },
      {
        target: '#inspectparsed',
        content: <div> 
          <p className='walkstep'>You can view the parsed text of the document on the site instead of downloading it.</p>
          <p className='walkstep' style={{marginTop: '1rem'}}>Let's click to view the text</p>
        </div>,
        spotlightClicks: true,
      },
      {
        target: '.parsed-text',
        title: 'Parsed text',
        content: <div> <p className='walkstep'>Here you can see the parsed text of the document. </p>
          <p className='walkstep'> If semantic search was used, the text that matched your query will be highlighted.</p>,
          <p className='walkstep'> Matching paragraphs can be navigated with a button, and highlights can be turned off.</p>
        </div> 
      },
      {
        disableBeacon: true,
        hideCloseButton: true,
        title: 'Exploring tagged documents',
        target: '#my-documents-nav',
        content: <div> <p className='walkstep'> Lastly, let's take a look at My Documents page. </p>
                       <p className='walkstep'> Press the document icon button to proceed. </p>
           </div>,
        spotlightClicks: true,
      },
    ];

    useEffect(() => {
      if (run) {
        window.addEventListener('storage', () => {
         {setTourStep(6); window.removeEventListener('storage', () => {})}
        }, );
      } else {
        window.removeEventListener('storage', () => {})
      }
    }, [run]);

    useEffect(() => {
      const walkthroughParam = params.get('walkthrough');
      const tab = params.get('tab');
      if (walkthroughParam) {
        setRun(true)
        // add it to session storage
        sessionStorage.setItem('walkthrough', 1)
      }
      if (tab == 'document') {
        setTourStep(8)
      }

    }, [params, location]);
    
    useEffect(() => {
      if (isOpen) {
        if  (tourStep === 2) {
          handleJoyrideCallback({action: ACTIONS.NEXT, index: 2, 
            status: STATUS.RUNNING, type: EVENTS.STEP_AFTER})
        }
      }
    }, [isOpen, tourStep]);

    const handleJoyrideCallback = (data) => {
      const { action, index, status, type } = data;
      if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status) || type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
        // Need to set our running state to false, so we can restart if we click start again.
        setRun(false);
        setTourStep(0);
        sessionStorage.removeItem('walkthrough')
        const params = new URLSearchParams(window.location.search);
        params.delete('walkthrough')
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setTourStep(nextStepIndex);
      }
    };


    const handleSnackbarShow = () => {
      setShowSnackBar(true);
    };
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowSnackBar(false);
    };

    const handleOpenDialogue = () => {
      setOpenSaveDialogue(true);
    };
  
    const handleCloseDialogue = () => {
      setOpenSaveDialogue(false);
    };

    const searchBtnRef = useRef()

    const triggerSearch = () => {
      const params = searchBtnRef.current.getParams()
      // console.log(Object.entries(params))
      setFilterParams(Object.entries(params))
      // console.log(params)
      performSearch(1)
    }

    useEffect(() => {
      if (props.tourCallbackFinished) {
        setTourStep(4);
      }
    }, [props.tourCallbackFinished]);
    

    const onSubmit = (e) => {
      e.preventDefault()
      // change page to 1
      const params = new URLSearchParams(window.location.search);
      params.set('tab', 'results')
      params.set('page', 1)
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      triggerSearch()
    }
    const processSaveSearch = () => {
      saveSearchRequest(searchBtnRef.current.getParams(), presetName).then((response) => {
        if (response.status === 201) {
          setMessage('Search saved successfully')
          handleSnackbarShow()
        }
      }).catch((error) => {
        setMessage('Error saving search')
        handleSnackbarShow()
      })
      setPresetName('')
      setOpenSaveDialogue(false)
    }

    useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          triggerSearch()
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }, []);

    const toggleFilters = () => {
      setIsOpen(!isOpen)
      if (run && tourStep === 1) {
        setTourStep(tourStep + 1)
        setShowTip(true)
      }
    }
    
    return (
        <Box>
        <Joyride
          callback={handleJoyrideCallback}
          run={run} 
          continuous 
          showProgress 
          data-testid="joyride" 
          steps={steps}
          stepIndex={tourStep}
          styles={{
            options: {
              arrowColor: '#e3ffeb',
              primaryColor: '#1976d2',
              textColor: '#004a14',
              width: 500,
              zIndex: 1000,
              beaconSize: 45,
            }
        }}
        ></Joyride>
        <Accordion open={isOpen} onChange={() => toggleFilters()}
         sx={{
            width: '100%',
            backgroundColor: 'unset',
            boxShadow: 'none',
        }}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', alignItems: 'center'}}> 
                <Box>
                  <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      expandIcon={
                          <Button className='filters-r' variant="outlined"  sx={{m: 0, width: 12,  bgcolor: 'white', p: 1}}> 
                              <TuneIcon sx={{ width: 26, height: 32}} />
                          </Button>
                      }
                  >
                  </AccordionSummary>
                </Box>
                <label>Filters</label>
                {isOpen? 
                <Box sx={{ml: 5}}>
                  <Tooltip title="show tips" placement="top">
                    <Button variant="outlined" onClick={() => setShowTip(!showTip)}  sx={{m: 0, width: 12, p: 1, bgcolor: 'rgba(255,255,255,0.92)'}}> 
                        <TipsAndUpdatesIcon sx={{ width: 26, height: 32}} />
                    </Button>
                  </Tooltip>
                </Box>
                : null}
              </div>
            <div> 
                <RegularCaseButton variant="text" onClick={() => handleOpenDialogue()}> 
                <p className='fnt-sz-14 semibold ml'> Save Search </p> 
                </RegularCaseButton>
              {props.isLoading ? 
                <LoadingButton loading variant="outlined">
                Submit
                </LoadingButton> 
              :<form style={{display:'contents'}} onSubmit={onSubmit}>
                <RegularCaseButton type="submit" ref={searchBtnRef} className='btn-search' variant="contained" 
                // onClick={() => triggerSearch()}
                  style={{margin: '16px 0 16px 16px'}}> 
                    <SearchIcon/> <p className='fnt-sz-14 semibold ml-2' style={{margin: '6px 0', padding: '0 12px'}}>Search </p>
                </RegularCaseButton>
                </form>
              }
                </div>
            </Box> 
            <AccordionDetails>
              <Filters showTip={showTip} resetFilters={props.resetFilters} ref={searchBtnRef}
              onTour={run} tourStep={tourStep} 
              />
            </AccordionDetails>
            <SaveSearchDialogue open={openSaveDialogue} name={presetName} setName={setPresetName} save={processSaveSearch}
             setOpen={setOpenSaveDialogue} handleClose={handleCloseDialogue}/>
            <SnackbarNotify open={showSnackBar} handleOpen={handleSnackbarShow} handleClose={handleSnackbarClose} message={message}/>
        </Accordion>
        </Box>
  );
}
import axios from "axios";

const keyTable = {
  'docName': 'document_name',
  'docFillingStartDate': 'document_filing_date__gte',
  'docFillingEndDate': 'document_filing_date__lte',
  'docId': 'document_identifier',
  'petitionerName': 'petitioner_party_name',
  'petitionerCounselName': 'petitioner_counsel_name',
  'proceedingNumber': 'proceeding_number',
  'respondentInventorName': 'respondent_inventor_name',
  'respondentPartyName': 'respondent_party_name',
  'respondentPatentOwnerName': 'respondent_patent_owner_name',
  'respondentCounselName': 'respondent_counsel_name',
  'respondentPatentNumber': 'respondent_patent_number',
  'respondentTechCenterNumber': 'respondent_technology_center_number',
  'respondentApplicationNumber': 'respondent_application_number_text',
  'respondentGroupArtUnitNumber': 'respondent_group_art_unit_number',
  'respondentGrantDateStart': 'respondent_grant_date__gte',
  'respondentGrantDateEnd': 'respondent_grant_date__lte',
}

function _appendParam(params, apiParams, key, icontains=false) {
  if (params.has(key)) {
    let value = params.get(key);
    // console.log(value, key)
    const apiKey = keyTable[key] ? keyTable[key] : key;
    if (value.endsWith('; ')) {
      value = value.slice(0, -2);
    }
    if (value.split('; ').length > 1 || apiKey.includes('respondent_counsel_name') // those are keys from foreign key model, that don't get searched directly for whatever reason, hack to add them as __in
    || apiKey.includes('respondent_patent_owner_name'))  { 
      console.log('contains')
      apiParams[`${apiKey}__in`] = value.split('; ').join('__');
    }
    else{
      const parValue = icontains ? `${apiKey}__icontains` : apiKey
      apiParams[parValue] = value;
    }
  }
  return apiParams;
}

export function fetchPtabDocuments(page=1, perPage=30, extra=null, combined=false) {
  if (!localStorage.getItem('token')) {
    // redirect to login page
    window.location.href = '/login';
  }
  return new Promise((resolve, reject) => {
    
    const params = new URLSearchParams(window.location.search);
    if (page==1 && params.get('page')) {
      page = params.get('page')
    }
    combined = combined || Boolean(params.get('q'))
    const url = `${process.env.REACT_APP_API_HOST}/api/v1/${combined && !extra? 'search' : 'documents'}`;
    const apiParams = {};
    if (combined) {
      apiParams['ag'] = 1;
      if (params.get('q')) {
        apiParams['q'] = params.get('q');
        // console.log(apiParams['q'], 'apiParams[q]')
      }
      if (params.get('qlimit')) {
        apiParams['qlimit'] = params.get('qlimit') || 3000;
      }
      if (params.get('sliderValue')) {
        apiParams['certainty'] = params.get('sliderValue');
      }
    }
    
    if (extra) {
      apiParams['id__in'] = extra?.join(',');
      apiParams['simple'] = true;
      apiParams['getTags'] = true;
    }
    else {
      for (let v of Object.keys(keyTable)) {
        let contains = true
        if (v.includes('Number') || v.includes('Id') || v.includes('Date')) {
          contains = false
        }
        _appendParam(params, apiParams, v, contains)
      }

      if (params.has('subCategories__in')) {
        apiParams['subproceeding_type_category__in']= params.get('subCategories__in');
      }
      if (params.has('subCategories')) {
        apiParams['subproceeding_type_category']= params.get('subCategories');
      }
      if (params.has('proceedingType')) {
        apiParams['proceeding_type_category']= params.get('proceedingType')
      }

      if (params.has('documentCategories__in')) {
        apiParams['document_category__in']= params.get('documentCategories__in')
      }
      if (params.has('documentCategories')) {
        apiParams['document_category']= params.get('documentCategories')
      }

      if (params.has('filingParty__in')) {
        apiParams['filing_party_category__in']= params.get('filingParty__in')
      }
      
      if (params.has('filingParty')) {
        apiParams['filing_party_category']= params.get('filingParty')
      }
      if (page != 1) {
        apiParams['offset'] = (page-1)*perPage;
      }
    }
    
    for (let p of params) { // don't leave hanging __, causes everything to match 
      if (p[1].endsWith('__')) {
        params[p[0]] = p[1].slice(0, -2);
      }
    }
    // console.log(apiParams)

    axios.get(url, 
      {
        params: apiParams,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + localStorage.getItem('token').replace(/['"]+/g, ''),
          "X-CSRFToken": global.csrftoken,
      }
    })
    .then((response) => {
      // console.log(response)
      resolve(response);
    }
    )
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainPage from './mainpage/Main';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignIn from './auth/SignIn';
import Register from './auth/Register';
import ProfilePage from './profile/ProfilePage';
import Navbar from './components/navbar/Navbar';
import {Outlet} from "react-router-dom";
import Footer from './components/footer/Footer';
import PasswordChange from './auth/PasswordChange';
import PasswordReset from './auth/PasswordReset';
import SavedSearchesPage from './profile/SavedSearchesPage';
import TagsPage from './tags/TagsPage';
import ChangePasswordForm from './profile/ChangePasswordForm';
import CreateProfile from './profile/CreateProfile';


const router = createBrowserRouter([
  { path: "/", element: <NavbarWrapper />, children: [
    {path: "/", element: <MainPage />},
    { path: "login", element: <SignIn /> },
    { path: "profile/create", element: <CreateProfile /> },
    { path: "register", element: <Register /> },
    { path: "profile", element: <ProfilePage /> },
    { path: "password-reset", element: <PasswordReset/> },
    { path: "password-reset-confirm", element: <PasswordChange/> },
    { path: "password-change", element: <ChangePasswordForm/>},
    { path: "searches", element: <SavedSearchesPage />},
    { path: "tags", element: <TagsPage />},
    { path: "*", element: <div>404</div>}
  ] },



]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
    <Footer />
  </>
);

function NavbarWrapper() {
  return (
  <div>
      <Navbar/>
      <Outlet/>
  </div>
  )
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

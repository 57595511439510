import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import getProfileData from "./actions/getProfileData";
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import patchProfileData from "./actions/patchProfileData";
import { useLocalStorage } from "../hooks/useLocalStorage";
import verifyEmail from "./actions/verifyEmail";
import "./ProfilePage.css"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {requestResetPassword} from "../auth/actions/resetPassword";
import {Link} from "react-router-dom";
import Typography from '@mui/material/Typography';
import moment from 'moment';

const VerificationBadge = () => {
  return (
    <Tooltip title="Verified">
      <IconButton style={{ color: 'green' }} size="small">
        <CheckCircleIcon />
      </IconButton>
    </Tooltip>
  );
}

const dateFormat = localStorage.getItem('dateFormat')
const dateTimeFormat = "HH:mm:ss "+ dateFormat
const renderDate = (datetimeString, time=false) => {
  const momentObj = moment(datetimeString);
  if (time) {
    return momentObj.format(dateTimeFormat);
  } else {
    return momentObj.format(dateFormat);
  }
}

const RenderDateSelect = ({date, handleChange}) => {
  return (
    <FormControl>
      <Select
        labelId="date-format-select-label"
        id="date-format"
        value={date}
        onChange={handleChange}
        style={{width: '160px'}}
      >
        <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
        <MenuItem value={'DD/MM/YYYY'}>DD/MM/YYYY</MenuItem>
      </Select>
    </FormControl>
  )
}

const RenderWeekStartSelect = ({weekStart, handleChange}) => {
  return (
    <FormControl>
      <Select
        labelId="week-start-select-label"
        id="week-start"
        value={weekStart}
        onChange={handleChange}
        style={{width: '160px'}}
      >
        <MenuItem value={1}>Monday</MenuItem>
        <MenuItem value={7}>Sunday</MenuItem>
      </Select>
    </FormControl>
  )
}

const ProfilePage = () => {
  const [profile, setProfile] = useState({})
  const [dateFormat, setDateFormat] = useState('')
  const [weekStart, setWeekStart] = useState('')
  const [verifyEmailSent, setVerifyEmailSent] = useState(false)
  const [requestedResetPassword, setRequestedResetPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (dateFormat) {
      localStorage.setItem('dateFormat', dateFormat)
    }
    const profileId = localStorage.getItem('profileId')
    setLoading(true)
    getProfileData(profileId).then(res => {
        setProfile(res.data)
        setLoading(false)
      }).catch(error => {
        console.log(error)
      }
    )
  }, [])

  useEffect(() => {
    if (profile?.settings?.date_format) {
      setDateFormat(profile.settings.date_format)
    }
    if (profile?.settings?.week_start) {
      setWeekStart(profile.settings.week_start)
      localStorage.setItem('weekStart', profile.settings.week_start)
    }
  }, [profile])

  const onRequestResetPassword = () => {
    requestResetPassword(profile.user.email).then(res => {
      setRequestedResetPassword(true)
    }).catch(error => {
      console.log(error)
    })
  }
    
  const handleDateFormatChange = (event) => {
    if ( event.target.value) {
      setDateFormat(event.target.value)
      patchProfileData(profile.id, {date_format: event.target.value}).then(res => {
        setProfile({...profile, settings: res.data})
        localStorage.setItem('dateFormat', event.target.value)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  const handleWeekStartChange = (event) => {
    if ( event.target.value) {
      setWeekStart(event.target.value)
      patchProfileData(profile.id, {week_start: event.target.value}).then(res => {
        setProfile({...profile, settings: res.data})
      }).catch(error => {
        console.log(error)
      })
    }
  }

  const sendVerifyEmail = () => {
    verifyEmail().then(res => {
      console.log(res)
      if (res.status === 200) {
        setVerifyEmailSent(true)
      }
    }).catch(error => {
      setVerifyEmailSent(false)
    })
  }
  const useStyles = makeStyles((theme) => ({
    customBox: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #d2d2d2',
      margin: theme.spacing(5),
      borderRadius: '6px',
      minWidth: '320px',
      overflow: 'scroll',
      // hide scrollbar
      scrollbarWidth: 'none', /* Firefox */
      msOverflowStyle: 'none',  /* IE 10+ */
      '&::-webkit-scrollbar': { /* WebKit */
        width: '0',
        height: '0',
      },
    },
  }));
  const classes = useStyles();

  return(
    <Container maxWidth="xl"> 
     <h2 style={{textAlign: 'center', marginTop: '14px'}} className="header-name"> Profile settings</h2>
     {profile?.id ?
      <React.Fragment>
      <Box className={classes.customBox}> 
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 2, borderColor: '#f1f1f1', width: '120px' }}
        >
         <p style={{marginBottom: '9px', fontWeight: 500}}> General </p> 
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <p className="prof-item">Name</p>
          <p className="prof-item">{profile?.user?.first_name ? profile.user.first_name : ""} {profile?.user?.last_name ? profile.user.last_name : ""}</p>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <p className="prof-item">Email</p>
          <p className="prof-item">{profile?.user?.email ? profile.user.email : "email"}
          <span className="m-5"> 
          {!profile?.id ? null :
            profile?.email_confirmed ? <VerificationBadge/> : 
            verifyEmailSent ? 'verification email sent' :
            <a className="link-style"
            onClick={() => sendVerifyEmail()}>verify</a>
          }
          </span>
          </p>
        </Stack>
     

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <p className="prof-item">Permissions</p>
          <p className="prof-item">{profile?.has_access ? "Full" : "Restricted"}</p>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <p className="prof-item">Joined</p>
          <p className="prof-item">{profile?.user?.date_joined ? renderDate(profile?.user?.date_joined) : ""}</p>
        </Stack>
     
      </Box>


      <Box className={classes.customBox}> 
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{ borderBottom: 2, borderColor: '#f1f1f1', width: '120px' }}
          >
          <p style={{marginBottom: '9px', fontWeight: 500}}> Preferences </p> 
        </Stack>


      {profile?.settings ?
      <>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <p className="prof-item">Date format</p>
          <Box className="prof-item" style={{display: 'flex', alignItems: 'center'}}>
            <RenderDateSelect date={profile.settings.date_format} handleChange={handleDateFormatChange}/> 
          </Box>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <p className="prof-item">Week start</p>
          <Box className="prof-item" style={{display: 'flex', alignItems: 'center'}}>
            <RenderWeekStartSelect weekStart={weekStart} handleChange={handleWeekStartChange} /> 
          </Box>
        </Stack>

    
       
        </>

        : null
        }

      </Box>
      <Box className={classes.customBox}> 
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{ borderBottom: 2, borderColor: '#f1f1f1', width: '120px' }}
          >
          <p style={{marginBottom: '9px', fontWeight: 500}}> Security </p> 
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <p className="prof-item">Last login</p>
          <p className="prof-item">{profile?.user?.last_login ? renderDate(profile?.user?.last_login, true) : "never"}</p>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <p className="prof-item">Password</p>
          <Box className="prof-item" style={{display: 'flex', alignItems: 'center'}}>
            {profile?.email_confirmed ? 
             !requestedResetPassword ? <a className="link-style-on-hover" onClick={() => onRequestResetPassword()}>Request password change</a> :
              <p> An email has been sent to change password </p> :
             
             <p>Verify email to change password</p>}
          </Box>
        </Stack>
       </Box>
      </React.Fragment>
      : loading ? 
        <Box 
          sx={{ p: 3, display: 'flex', flexDirection: 'column',
            border: '1px solid #d2d2d2', m: 5, borderRadius: '6px'
        }}> 
        <p>Loading profile...</p>
        </Box> 
        :
      <Link to="/login">To view your profile please <span className="link-style">Log In </span>  </Link>
      }
    </Container>
    )
}

export default ProfilePage;
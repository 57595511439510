import React, {useCallback} from "react";
import SearchField from "./SearchField";
import Grid from '@mui/material/Grid'; 
import RadioPanel from "./RadioPanel";
import Selector from './Selector';
import FilterContainer from '../filters/FiltersContainer';
import ResultTabs from '../../resultTabs/searchTabs';
import '../../App.css'
import { fetchPtabDocuments } from '../../components/actions/fetchPtabDocument';
import queryWeaviate  from '../../components/actions/queryWeaviate';
import { useSearchResultsContext, SearchResultsContext } from '../../context/SearchResultsContext';
import moment from 'moment';
import dummyData from "../../search/dummyData";

const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
}

function groupJsonData(data, groupFields) {
  const groupedData = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const objects = data[key];

      // Extract selected fields for grouping
      const groupKey = JSON.stringify(objects.map(obj => {
        const groupObj = {};
        groupFields.forEach(field => {
          if (obj.hasOwnProperty(field)) {
            groupObj[field] = obj[field];
          }
        });
        return groupObj;
      }));

      // Create a new entry in groupedData or add to an existing one
      if (!groupedData[groupKey]) {
        groupedData[groupKey] = [];
      }
      groupedData[groupKey] = groupedData[groupKey].concat(objects);
    }
  }
  return groupedData;
}

const translateOperator = (operator) => {
  if (operator === '>') {
    return 'gte';
  } else if (operator === '<') {
    return 'lte';
  } else if (operator === '=') {
    return 'eq';
  }
}

const cleanParams = (param) => {
  const qParams = getQueryParams();
  qParams.delete(param + '__gte');
  qParams.delete(param + '__lte');
  qParams.delete(param + '__eq');
}

const SearchPage = (props) => {
    const [options, setOptions] = React.useState([{'label': 'document', 'value':'document'},
                                                {'label': 'paragraph', 'value':'paragraph'},
                                                {'label': 'sentence', 'value':'sentence'},
                                                {'label': 'page', 'value':'page'}
                                            ]);
    const selectorValue = 'document';
    const [selectorHidden, setSelectorHidden] = React.useState(true);
    const [searchResults, setSearchResults] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [docSizeOperator, setDocSizeOperator] = React.useState('');
    const [docSize, setDocSize] = React.useState('');
    const [tourCallbackFinished, setTourCallbackFinished] = React.useState(false);
    const [run, setRun] = React.useState(false);
    const [tourStep, setTourStep] = React.useState(0);
 
    React.useEffect(() => {
      const params = getQueryParams();
      if (params.toString()) {
        performSearch();
      }
      // setSearchResults(dummyData);
    }, [])
    const setUrlParams = (paramStr, paramValue) => {
      const params = getQueryParams();
      if (paramValue) {
        if (typeof paramValue === 'object') {
          if (paramValue instanceof moment) {
            paramValue = paramValue.format('YYYY-MM-DD');
            params.set(paramStr, paramValue);
          }
          else if(paramValue.length === 0) {
            params.delete(paramStr);}
          else if (paramValue.length === 1) {
            params.set(paramStr, paramValue[0]);
          } else {
            params.set(paramStr +'__in', paramValue.join('__'));
          }
         } else {
          if (paramValue === '') {
            params.delete(paramStr);
          } else if (paramStr === 'docSizeOperator') {
            setDocSizeOperator(paramValue);
          } else if (paramStr === 'docSize') {
              setDocSize(paramValue);
          } else {
            params.set(paramStr, paramValue)
          }
        }
      } else {
        params.delete(paramStr);
      }
      // console.log(params.toString())
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }

    const setFilterParams = (arr) => {
      for (let i = 0; i < arr.length; i++) {
          setUrlParams(...arr[i]);
      } 
      if (docSizeOperator && docSize) {
        cleanParams('docSize'); // remove old doc size
        setUrlParams('docSize__' + translateOperator(docSizeOperator), docSize);
      }
    }
    const resetFilters = () => {
      setDocSize('')
      cleanParams('docSize');
    }

    const metasearchUsed = (params) => { // if any values besides this are in params, then query sql db
      const allowedKeys = ['page', 'q', 'slider_value', 'tab'];
      for (const key of params.keys()) {
        if (!allowedKeys.includes(key)) {
          return true; 
        }
      }
    }

    const performSearch = (page=1) => {
      setIsLoading(true);
      const params = new URLSearchParams(window.location.search);
      const searchesUsed = [];
      if (params.has('q') && params.get('q') !== '') {
        searchesUsed.push('vector');
      }
      if (metasearchUsed(params)) {
        searchesUsed.push('metasearch');
      }
      if (searchesUsed.length === 0) {
        setIsLoading(false);
        return;
      } else if (searchesUsed.length === 1 && searchesUsed[0] === 'vector') {
        // console.log('vector')
        fetchPtabDocuments(page, undefined, undefined, true).then((response) => {
          setSearchResults(response.data);
          // console.log(response)
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
      } else if (searchesUsed.length === 1 && searchesUsed[0] === 'metasearch') {
        // console.log('meta')
        fetchPtabDocuments().then((response) => {
          setSearchResults(response.data);
          setTourCallbackFinished(true);
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
      } else {  // query combined 
        // console.log('combined')
        fetchPtabDocuments(page, undefined, undefined, true).then((response) => {
          setSearchResults(response.data);
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
      }
    }
    
    return (
      <SearchResultsContext.Provider value={{data: searchResults, setSearchResults, run, setRun, tourStep, setTourStep}}>
        <Grid container py={3} alignItems={'center'} justifyItems='space-around'>
          <Grid item  xs={8} px={1}>
              <SearchField  />
              </Grid>
          {/* <Grid xs={4} px={1}> <AddCriteria /></Grid> */}
        </Grid>
        <Grid container alignItems={'center'} justifyContent={'left'}>
          <Grid item xs={8} px={1}>  
            {/* <RadioPanel /> */}
            {selectorHidden ? null : <Selector options={options} value={selectorValue}/>}
          </Grid>
        </Grid>
        <FilterContainer tourCallbackFinished={tourCallbackFinished} isLoading={isLoading} performSearch={performSearch} 
          setFilterParams={setFilterParams} resetFilters={resetFilters}/>
          
          {searchResults? <ResultTabs setIsLoading={setIsLoading} isLoading={isLoading} /> : null}
        <div style={{height: '5px'}}> </div>
      </SearchResultsContext.Provider>
    );
}

export default SearchPage;
export {SearchResultsContext, useSearchResultsContext};
import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchResultsPage from '../search/SearchResultsPage';
import DocumentPage from './DocumentPage';
import ParsedDocumentPage from './ParsedDocumentPage'
import { useSearchResultsContext } from '../context/SearchResultsContext';



function TabPanel (props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ResultTabs = (props) => {
  const [tab, setTab] = React.useState(0);
  const [selectedDocuments, setSelectedDocuments] = React.useState([])
  const [viewParsedDocument, setViewParsedDocument] = React.useState(false)
  const [matchedParagraphs, setMatchedParagraphs] = React.useState({})
  const {isLoading, setIsLoading} = props
  const {run, tourStep, setTourStep} = useSearchResultsContext()

  const handleChange = (event, newValue) => {
    setTab(newValue);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('tab', newValue == 0? 'results' : newValue == 2? 'parsed' : 'document')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    if (run && tourStep === 6) {
      setTimeout(() => {
        setTourStep(7);
      }, 500);
    }    
  };
  const addDocument = (id) => {
    if (typeof id == 'number' || typeof id == 'string') {
      setSelectedDocuments([...selectedDocuments, id])
      sessionStorage.setItem('selectedDocuments', JSON.stringify([...selectedDocuments, id]))
    } else {
      setSelectedDocuments([...selectedDocuments, ...id])
      sessionStorage.setItem('selectedDocuments', JSON.stringify([...selectedDocuments, ...id]))
    }
    window.dispatchEvent(new Event("storage"));
    // add to session storage
  }

  const onSetMatchedParagraphs = (docId, parNumList) => {
    if (docId && parNumList.length){
      // console.log(docId, parNumList, 'docId, parNumList')
      setMatchedParagraphs({...matchedParagraphs, [docId]: parNumList})
    }
  }

  const removeDocument = (id) => {
    const newDocuments = selectedDocuments.filter((doc) => doc !== id)
    sessionStorage.setItem('selectedDocuments', JSON.stringify(newDocuments))
    setSelectedDocuments(newDocuments)
  }
  const clearDocuments = () => {
    setSelectedDocuments([])
    sessionStorage.setItem('selectedDocuments', JSON.stringify([]))
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTab(tab == 'results'? 0 : tab == 'parsed'? 1 : 2)
    }
    if (tab == 'document') {
      const selectedDocuments = sessionStorage.getItem('selectedDocuments')
      if (selectedDocuments) {
        setSelectedDocuments(JSON.parse(selectedDocuments))
      }
    }
  }, [])

  return (

    <Box sx={{ width: '106%', backgroundColor: 'white', marginLeft: '-3%'}}>
    
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange}  style={{padding: '12px'}} aria-label="basic tabs example">
          <Tab id="results" label="Results" {...a11yProps(0)} />
          <Tab id="pdfmeta" label="PDF & metadata" {...a11yProps(2)}  disabled={!selectedDocuments}  />
          <Tab id="documenttext" label="Document text" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <SearchResultsPage setTab={setTab} setViewParsedDocument={setViewParsedDocument} setIsLoading={setIsLoading} isLoading={isLoading} selectDocuments={addDocument} setMatchedParagraphs={onSetMatchedParagraphs}/>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <DocumentPage setTab={setTab} selectedDocuments={selectedDocuments} viewParsedDocument={viewParsedDocument} setViewParsedDocument={setViewParsedDocument} clearDocuments={clearDocuments} removeDocument={removeDocument}/>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ParsedDocumentPage matchedParagraphs={matchedParagraphs} parsedDocumentMeta={viewParsedDocument}/>
      </TabPanel>
    </Box>
  );
}
export default React.memo(ResultTabs)
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 60,
    label: 'Loose',
  },
  {
    value: 70,
    label: 'Normal',
  },
  {
    value: 80,
    label: 'Strict',
  }
];

export default function ProximitySlider({value, changeHandler}) {
  React.useEffect(() => {  // hacky ass way to move slider label
    const el = document.querySelector('[data-index="0"].MuiSlider-markLabel')
    el.style.left = "8%"
  }, []);


  return (
    <Box sx={{ width: 250 }}>
      <Slider
        aria-label="Search"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={60}
        max={80}
        color="secondary"
        onChange={changeHandler}
        value={value}
      />
    </Box>
  );
}
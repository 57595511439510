import React from "react";
// import DatePicker from "react-datepicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from '@mui/material/TextField';

const DatePickerYear = ({startDate, setStartDate, label, disablePast, minDate, halfSized, maxDate}) => {
  const dateFormat = localStorage.getItem('dateFormat')
  // ?.replace(/DD/g, 'dd')?.replace(/YYYY/g, 'yyyy') || 'dd/MM/yyyy'
  const weekStart = localStorage.getItem('weekStart') || 1

  moment.updateLocale("en", {
    week: {
      dow: Number(weekStart),
    }
  });

  const color = "#fff"
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} >
      <DatePicker
        selected={startDate}
        value={startDate}
        onChange={(date) => setStartDate(date)}
        peekNextMonth
        format={dateFormat}
        showMonthDropdown
        showYearDropdown
        label={label}
        dropdownMode="select"
        disablePast={disablePast || false}
        minDate={minDate || false}
        maxDate={maxDate || false}
        dayOfWeekFormatter={(day) => `${day}`}
        sx={{
          "& input": { backgroundColor: color },
          width: halfSized ? '160px' : '100%',
          display:  halfSized ?  'table-cell' : 'block',
        }}
        slotProps={{
          actionBar: { actions: ['clear'] },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerYear;
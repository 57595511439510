import React from 'react'
import SearchHeader from './SearchHeader'
import SearchFooter from './SearchFooter'
import SearchResults from './SearchResults'

const SearchResultsPage = (props) => {
  const isLoading = props.isLoading || false
  const setIsLoading = props.setIsLoading 

  return (
    <div style={{marginTop: '26px'}}>
      <SearchHeader isLoading={isLoading}/>
      <SearchResults setTab={props.setTab} setViewParsedDocument={props.setViewParsedDocument} isLoading={isLoading}  selectDocuments={props.selectDocuments} setMatchedParagraphs={props.setMatchedParagraphs}/>
      <SearchFooter setIsLoading={setIsLoading} isLoading={isLoading} />
    </div>
  )
}

export default SearchResultsPage

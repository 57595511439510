import axios from "axios";

export default function setTagToDocumentRequest(tag, caseId) {
  const token = localStorage.getItem('token').replace(/^"(.*)"$/, '$1');
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API_HOST}/api/v1/tags/case`;
    axios.post(url,
      {tag, case: caseId}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
     
    })
    .then((response) => {
      resolve(response);
    }
    )
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}

    
import React, { useState, useEffect } from 'react';
import createProfileFromPromo from './actions/createProfileFromPromocode';
import { Link, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RegularCaseButton from '../components/buttons/RegularButton';
import { FormControl, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from '@mui/material/TextField';
import SnackbarNotify from '../components/SnackbarNotify';
import Paper from '@mui/material/Paper';



const CreateProfile = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [progress, setProgress] = useState(0);
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [handleMouseDownPassword, setHandleMouseDownPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    //if logged in already, redirect to home
    const storedToken = localStorage.getItem("token");
    if (storedToken !== 'null' && storedToken !== undefined && storedToken !== null) {
      window.location.href = "/profile";
    }
    const params = new URLSearchParams(window.location.search); // read promo code from URL params
    const promo = params.get('promo');
    if (!promo) {
      setError("No promo code found! Check your link or try again");
      setLoading(false);
      setProgress(0);
      return;
    }
    setProgress(10);
    const progressInterval = setInterval(() => {
      // Increment the progress at a stable rate.
      setProgress((prevProgress) => Math.min(prevProgress + 3, 80));
    }, 100); // Update the progress every second (adjust the interval as needed).
    
    
    createProfileFromPromo(promo)
      .then((response) => {
        console.log(response);
        if (response?.status === 201) {
          clearInterval(progressInterval);
          setProgress(100);
          setUserPassword(response.data.password);
          setSuccess(true);
          setError("");
        }
        else {
          console.log(response)
          setError("Error creating profile: " + response?.data?.message  || "Unknown error");
        }
      })
      .catch(error => {
        console.log(error);
        setError("Error creating profile" + error?.data?.message || "Unknown error");
      })
      .finally(() => {
        setLoading(false);
        clearInterval(progressInterval);

      })
  }
  , []); // only run once on mount

  const onCopyPassword = (password) => {
    navigator.clipboard.writeText(password);
    setOpenSnackbar(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  }
  const color = "#222849"

  return (
    <Container maxWidth="xl"> 
      <Box p={2}>
       <Typography variant="h4" color={color} gutterBottom> Creating your Xlege profile</Typography>
      {progress < 100 && !error ?
        <React.Fragment>
          <Typography mt={2} color={color} className='d-block' variant='body'> Please wait while we're creating your profile... </Typography> 
          <LinearProgress className='my-4 p-1' style={{height: '12px'}} variant="determinate" value={progress}  />
        </React.Fragment>
        : 
        <Box px={1} py={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Paper square={false} style={{padding: '20px 26px'}}> 
          {error ? <div> 
            <Typography className='mt-3 p-3 d-block' variant='body'>{error}.</Typography>
           </div>
          : null}
        {success ? 
          <React.Fragment>
            <Typography style={{padding: '0.75rem 0 1.5rem 0', textAlign: 'center'}} color={color} variant="h4"> You're all set! </Typography>
            <Box p={2}> 
              <SnackbarNotify handleClose={handleClose} open={openSnackbar} duration={1500} setOpen={setOpenSnackbar} message="Password copied to clipboard!" severity="success" />
              <Typography className='d-block' variant="body"> Your temporary password is here: </Typography>
              <FormControl sx={{ width: '40ch', my: 2 }}  >
                <TextField
                  variant="outlined"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={userPassword}
                  onChange={(event) => (event.preventDefault())}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <IconButton
                          aria-label="copy password"
                          onClick={() => onCopyPassword(userPassword)}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Typography mt={1} py={1} size={22} className='d-block' variant="body"> We've sent it to your email. You can change it in your profile settings. </Typography> 
              <Typography mt={1} py={1}  className='d-block' variant="body"> You can now log in with this password.</Typography>
              <Link className="mt-5" to="/login"> 
                <RegularCaseButton size="large" variant="contained" color="success" style={{height: '42px', margin: '20px 0'}}
                >
                  <b> Sign In </b>
                </RegularCaseButton>
              </Link>
            </Box>
          </React.Fragment>
          
       : null}  
         </Paper>
        </Box>
        
      }
      </Box>
    </Container>
  );
}

export default CreateProfile;
import React, {useState} from "react";
import RegularCaseButton from "../buttons/RegularButton";
import LoginRegisterPanel from "../../auth/LoginRegisterPanel";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Container from '@mui/material/Container';
import ProfileMenu from "./ProfileMenu";

export default function Navbar(props) {
  const [signInOpen, setSignInOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const handleOpenSignIn = () => setSignInOpen(true);
  const handleOpenSignUp = () => setSignUpOpen(true);
  const navigate = useNavigate()
  const {user, signIn, signUp, signOut} = useAuthContext()

  const handleClose = () => {
    setSignInOpen(false)
    navigate(-1)
  }

  const handleCloseSignUp = () => {
    setSignUpOpen(false);
    navigate(-1)
  }


  return (
    <Container className='gradient-bckg' maxWidth="xl">
      <nav style={{justifyContent: "space-between", display: 'flex', padding: '2% '}}>
        <div>
            <a href="/"> 
                <img src="logo.png" alt="Logo" style={{height: '36px'}} />
            </a>
        </div>
        <div> 
            
        {user ?
            <> 
            <ProfileMenu signOut={signOut}/>
            {/* <Link to="/profile">
              <RegularCaseButton size="large" variant="contained" style={{height: '48px'}}>
                <b> Profile </b>
              </RegularCaseButton> 
            </Link>
            <RegularCaseButton onClick={signOut}
              size="large" variant="outlined" style={{height: '48px', marginLeft: '2.5rem'}}>
              <b> Logout </b>
            </RegularCaseButton> */}
          </>
              :
          <> 
            <Link to="/register">
            <RegularCaseButton size="large" variant="outlined" style={{height: '48px', marginRight: '2.5rem'}}
              onClick={() => setSignUpOpen(true)}>
                <b> Register </b>
              </RegularCaseButton> 
            </Link>
            <Link to="/login">
              <RegularCaseButton size="large" variant="contained"  color="success" style={{height: '48px'}}
                onClick={handleOpenSignIn}
              >
                <b> Sign In </b>
              </RegularCaseButton>
            </Link>
          </>
          }
        </div>
      </nav>
      <LoginRegisterPanel signInOpen={signInOpen} signUpOpen={signUpOpen} handleOpenSignIn={handleOpenSignIn}
        handleClose={handleClose} handleCloseSignUp={handleCloseSignUp} handleOpenSignUp={handleOpenSignUp}
      />
    </Container>
  );
}
import React from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSearchResultsContext } from '../context/SearchResultsContext';
import { fetchPtabDocuments } from '../components/actions/fetchPtabDocument';

const SearchFooter = (props) => {
  const {data, setSearchResults } = useSearchResultsContext()
  const perPage = 30
  const fileCount = data?.results ? data.count : 0
  const pageCount = data?.pages || 0
  const [currentPage, setCurrentPage] = React.useState(1)
  const {isLoading, setIsLoading} = props
  const setParams = (page) => {
    const params = new URLSearchParams(window.location.search)
    params.set('page', page)
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
  }

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const page = params.get('page') || 1
    setCurrentPage(Number(page))
  }, [])

  const fetchPage = (page) => {
    setIsLoading(true)
    fetchPtabDocuments(page, perPage).then((response) => {
      setSearchResults(response.data)
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)
    })

  }

  const onChangePage = (event, page) => {
    window.scrollTo(0, 250)
    setCurrentPage(page)
    setParams(page)
    fetchPage(page)
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', margin: '1rem 0 2rem 0'}} className='pagination-container'>
      <Stack spacing={2}>
        <Pagination disabled={pageCount<2} count={pageCount} shape="rounded" page={currentPage}
        hidePrevButton={currentPage === 1} hideNextButton={currentPage === pageCount} 
        onChange={onChangePage}
        />
      </Stack>
    </div>
  )
}

export default SearchFooter

import { useState, useEffect } from "react";
import { fetchSingleDocument } from "./actions/fetchSingleDocument";
import { fetchPtabDocuments } from "../components/actions/fetchPtabDocument";
import DocumentCard from './DocumentView'
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Tooltip } from "@mui/material";
import fetchUserTags from "../tags/actions/fetchUserTags";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SnackbarNotify from '../components/SnackbarNotify';
import setTagToDocumentRequest from "../tags/actions/setTagToDocument";
import RenderTag from "../components/RenderTag";
import SortMenu from "../components/SortMenu";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useSearchResultsContext } from '../context/SearchResultsContext';


function BookmarkDialog(props) {
  const {open, handleClose, userTags, setTagToDocument, doc} = props;
  

  return (
    <div>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle> <p style ={{textAlign: 'center', padding: '1rem'}}> Set a tag for  </p>
           <p style={{fontStyle: 'italic', padding: '0.5rem'}}> {doc?.document_title_text} </p></DialogTitle>
        <DialogContent>
          {userTags ? userTags.map((tag) => {
            return (
              <Button style={{cursor: 'pointer'}} onClick={() => setTagToDocument(tag.id, doc.document_identifier)}>
               <RenderTag tag={tag} />
              </Button>
            )
          }
          ) : null}
      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const DocumentPage = (props) => {
  const selectedDocuments = props.selectedDocuments
  const [documents, setDocuments] = useState(null)
  const [userTags, setUserTags] = useState(null)
  const [open, setOpen] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null)
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [sortBy, setSortBy] = useState('')
  const [sortedDocuments, setSortedDocuments] = useState(null)
  const [ascending, setAscending] = useState(true)
  const {run, setRun, tourStep, setTourStep} = useSearchResultsContext() || {};

  const handleSnackbarShow = () => {
    setShowSnackBar(true);
  };

  useEffect(() => {
    if (documents?.length && sortBy) {
      const sortedDocs = sortDocuments(documents)
      setSortedDocuments(sortedDocs)
    }
  }, [sortBy, documents, ascending])

  const sortDocuments = (docs) => {
    if (!docs || !sortBy) return docs;
  
    const sorted = [...docs];
    const multiplier = ascending ? 1 : -1;
  
    switch (sortBy) {
      case 'title':
        sorted.sort((a, b) => (a.document_title_text > b.document_title_text ? 1 : -1) * multiplier);
        break;
      case 'document date':
        sorted.sort((a, b) => (a.document_filing_date > b.document_filing_date ? 1 : -1) * multiplier);
        break;
      case 'tag name':
        sorted.sort((a, b) => (a?.tag[0]?.tag?.tag > b?.tag[0]?.tag?.tag ? 1 : -1) * multiplier);
        break;
      case 'date tagged':
        sorted.sort((a, b) => (new Date(a?.tag[0]?.tag?.created) > new Date(b?.tag[0]?.tag?.created) ? 1 : -1) * multiplier);
        break;
      default:
        return docs;
    }
  
    return sorted;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (doc) => {
    setOpen(true);
    setCurrentDoc(doc)
  }

  const setTagToDocument = (tagId, docId) => {
    setTagToDocumentRequest(tagId, docId).then((response) => {
      if (response.status ===200 || response.status === 201) {
        setMessage('Document tagged successfully')
        handleSnackbarShow()
        handleClose()
        if (run && tourStep == 8) {
          setTourStep(9)
        }
      }
    }).catch((error) => {
      setMessage('Something went wrong')
      handleSnackbarShow()
    })
  }

  const removeDocFromList = (id) => {
    const newDocuments = documents.filter((doc) => doc.id !== id)
    setDocuments(newDocuments)
    props.removeDocument(id)
  }

  const clearDocuments = () => {
    setDocuments(null)
    props.clearDocuments()
  }

  useEffect(() => {
    if(selectedDocuments.length == 1) {
      fetchSingleDocument(selectedDocuments[0]).then((response) => {
        setDocuments([response.data])
      })
    } else if (selectedDocuments.length >= 1){
      fetchPtabDocuments(undefined, undefined, selectedDocuments).then((response) => {
        setDocuments(response.data.results)
      }
      )
    }
  }, [])

  useEffect(() => {
    fetchUserTags().then((response) => {
      setUserTags(response.data)
    } )
  }, [])

  const docs = sortedDocuments ? sortedDocuments : documents
  return (
    <div style={{minHeight: '50vh', minWidth: '50vw'}}> 
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '1rem'}}> 
      <Typography component="h1" variant="h5" color={"#494949"}>
        Selected documents </Typography>
        <div> 
          <Tooltip title="Clear all" placement="top">
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => clearDocuments()}
              size="large"
            >
              <DeleteOutlineIcon sx={{ width: 32, height: 32, color: 'red' }}/>
            </IconButton>
          </Tooltip>
          <SortMenu setSortBy={setSortBy} sortBy={sortBy} options={['title', 'document date', 'tag name', 'date tagged']}/>
          <Tooltip title={ascending ? "Asc" : "Desc"} placement="top">
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setAscending(!ascending)}
              size="large"
              >
                {ascending ? <ArrowUpwardIcon sx={{ width: 32, height: 32 }}/> :
                  <ArrowDownwardIcon sx={{ width: 32, height: 32 }}/> }
              </IconButton>
          </Tooltip>
        </div>
      </div>
      <SnackbarNotify open={showSnackBar} duration={1500} handleOpen={handleSnackbarShow} handleClose={handleSnackbarClose} message={message}/>
      {docs?.map((document) => {
        return (
        <div className="document-container">
          <DocumentCard data={document} remove={removeDocFromList} clear={clearDocuments}
            openDialog={handleOpen} _setViewParsedDocument={props.setViewParsedDocument} setTab={props.setTab}
          />
        </div>  
        ) }) 
        }
        {!docs?.length ? <p style={{textAlign: 'center', padding: '1rem'}}> No documents selected </p> : null}
      <BookmarkDialog userTags={userTags} open={open} doc={currentDoc} handleClose={handleClose} setTagToDocument={setTagToDocument}/>
    </div>
  )}

export default DocumentPage
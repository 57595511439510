import axios from "axios";

export function fetchParsedDocument(docId) {
  if (!localStorage.getItem('token')) {
    // redirect to login page
    window.location.href = '/login';
  }
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_HOST}/api/v1/documents/${docId}/parsed`;
    axios.get(url, 
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + localStorage.getItem('token').replace(/['"]+/g, ''),
          "X-CSRFToken": global.csrftoken,
      }
    })
    .then((response) => {
      // console.log(response)
      resolve(response);
    }
    )
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}
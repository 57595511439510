import axios from "axios";

export default function patchProfileData(id, data) {
  const token = localStorage.getItem('token').replace(/^"(.*)"$/, '$1');
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API_HOST}/api/v1/profile-settings/${id}/`;
    axios.patch(url,  data,
      {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    })
    .then((response) => {
      resolve(response);
    }
    )
    .catch((error) => {
      reject(error);
    }
    );
  }
  );
}

    
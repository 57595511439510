import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RegularCaseButton from '../buttons/RegularButton';
import ProximitySlider from '../slider/ProximitySlider';
import DatePickerYear from '../Datepicker';
import SelectFilter from './SelectFilter';
import MultipleSelect from './MultipleSelect';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  half: {
    width: '47%',
    backgroundColor: 'white',
    margin: '0 1.5%',
    height: 'fit-content',
  }
}));


const Filters = forwardRef((props, ref) => {

  const classes = useStyles();
  const [docFillingStartDate, setDocFillingStartDate] = useState();
  const [docFillingEndDate, setDocFillingEndDate] = useState();
  const [docName, setDocName] = useState('');
  const [docId, setDocId] = useState(null);
  const [petitionerName, setPetitionerName] = useState('');
  const [petitionerCounselName, setpetitionerCounselName] = useState('');
  const [proceedingNumber, setProceedingNumber] = useState(''); 
  const [docSize, setDocSize] = useState(0);
  const [docSizeOperator, setDocSizeOperator] = useState('>');
  const [sliderValue, setSliderValue] = useState(70);
  
  const showTip = props.showTip;

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  }

  useImperativeHandle(ref, () => ({
    getParams() { 
      return {docName, petitionerName, docId, docFillingStartDate, docFillingEndDate, proceedingNumber,
         docSize, petitionerCounselName, respondentInventorName, respondentPartyName, respondentPatentOwnerName, respondentCounselName,
         respondentPatentNumber, respondentTechCenterNumber, respondentApplicationNumber, respondentGroupArtUnitNumber, respondentGrantDateStart, respondentGrantDateEnd,
         documentCategories, subCategories, filingParty, proceedingType, sliderValue, docSizeOperator
        }
    } 
  }));


  const handleDocFillingEndDateChange = (date) => {
    if (docFillingStartDate && date > docFillingStartDate) {
      setDocFillingEndDate(date)
    } else if (docFillingStartDate) {
      setDocFillingEndDate(null)
    }
  };
  const clearParam = (param) => {
    const qParams = new URLSearchParams(window.location.search);
    qParams.delete(param + '__gte');
    qParams.delete(param + '__lte');
    qParams.delete(param + '__eq');
    qParams.delete(param + '__in');
    qParams.delete(param + '__undefined');
    qParams.delete(param)
    window.history.replaceState({}, '', `${window.location.pathname}?${qParams}`);
  }
  const resetFilters = () => {
    setDocumentCategories([]);
    setSubCategories([]);
    setFilingParty([]);
    setProceedingType('');
    setDocName('');
    setPetitionerName('');
    setDocId(null);
    setDocFillingEndDate(null);
    setDocFillingStartDate(null);
    setDocSize(0);
    setDocSizeOperator('>');
    setpetitionerCounselName('');
    setProceedingNumber('');
    setRespondentInventorName('');
    setRespondentPartyName('');
    setRespondentPatentOwnerName('');
    setRespondentCounselName('');
    setRespondentPatentNumber('');
    setRespondentTechCenterNumber('');
    setRespondentApplicationNumber('');
    setRespondentGroupArtUnitNumber('');
    setRespondentGrantDateStart(null);
    setRespondentGrantDateEnd(null);
    handleSliderChange(null, 70);
    props.resetFilters(); // clears docsize in SearchPage.js

    const qParams = new URLSearchParams(window.location.search);
    const paramsList = ['docName', 'petitionerName', 'docId', 'documentCategories',  'subCategories', 'filingParty', 'docFillingStartDate', 'docFillingEndDate', 'docSize', 'petitionerCounselName', 'docSizeOperator',
                        'respondentInventorName', 'respondentPartyName', 'respondentPatentOwnerName', 'respondentCounselName', 'respondentPatentNumber', 'proceedingNumber',
                        'respondentTechCenterNumber', 'respondentApplicationNumber', 'respondentGroupArtUnitNumber', 'respondentGrantDateStart', 'respondentGrantDateEnd', 'sliderValue', 'proceedingType' ];
    paramsList.forEach(param => {
      qParams.delete(param);
      qParams.delete(param + '__gte');
      qParams.delete(param + '__lte');
      qParams.delete(param + '__eq');
      qParams.delete(param + '__in');
      qParams.delete(param + '__undefined');
    });
    qParams.delete('page')
    window.history.replaceState({}, '', `${window.location.pathname}?${qParams}`);
  }

  // document_category
  const documentCategoriesLabels = ['Decision', 'Exhibit', 'Final', 'Motion', 'Notice', 'Opposition', 'Order', 'Other', 'Paper', 'Petition', 'Popr', 
                                    'Rehearing', 'Reply', 'Replytoopp', 'Response', 'Terminate']
  const [documentCategories, setDocumentCategories] = useState([]);
  const handleDocumentCategoriesChange = (event) => {
    clearParam('documentCategories');
    setDocumentCategories(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };
  // subproceeding_type_category
  const subCategoriesLabels = [ 'CBM', 'IPR', 'PGR', 'Regular']
  const [subCategories, setSubCategories] = useState([]);
  const handleSubCategoriesChange = (event) => {
    clearParam('subCategories');
    setSubCategories(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };
  // filing_party_category
  const filingPartyLabels = ['Petitioner', 'Board', 'Patent Owner']
  const [filingParty, setFilingParty] = useState([]);
  const handleFilingPartyChange = (event) => {
    clearParam('filingParty');
    setFilingParty(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };
  // proceeding_type_category
  const proceedingTypeLabels = ['Appeal', 'AIA Trial', '(clear)']
  const [proceedingType, setProceedingType] = useState('');

  const handleProceedingTypeChange = (event) => {
    if (event.target.value === '(clear)') {
      setProceedingType('');
      clearParam('proceedingType');
    } else {
      setProceedingType(event.target.value);
    }
  };
  // respondents
  const [respondentInventorName, setRespondentInventorName] = useState('');
  const [respondentPartyName, setRespondentPartyName] = useState('');
  const [respondentPatentOwnerName, setRespondentPatentOwnerName] = useState('');
  const [respondentCounselName, setRespondentCounselName] = useState('');
  const [respondentPatentNumber, setRespondentPatentNumber] = useState('');
  const [respondentTechCenterNumber, setRespondentTechCenterNumber] = useState('');
  const [respondentApplicationNumber, setRespondentApplicationNumber] = useState('');
  const [respondentGroupArtUnitNumber, setRespondentGroupArtUnitNumber] = useState('');
  const [respondentGrantDateStart, setRespondentGrantDateStart] = useState();
  const [respondentGrantDateEnd, setRespondentGrantDateEnd] = useState();

  React.useEffect(() => {
    loadStateFromParams();
  }, []);

  React.useEffect(() => {
    if (props.onTour && props.tourStep === 3) {
      clearParam('documentCategories');
      setDocumentCategories(["Exhibit"])
    }
  }, [props.tourStep]);

  const loadStateFromParams = () => {
    const qParams = new URLSearchParams(window.location.search);
    let paramsList = ['docName', 'petitionerName', 'docId', 'documentCategories', 'subCategories', 'filingParty', 'docFillingStartDate', 'docFillingEndDate', 'docSize', 'petitionerCounselName', 'docSizeOperator',
                        'respondentInventorName', 'respondentPartyName', 'respondentPatentOwnerName', 'respondentCounselName', 'respondentPatentNumber', 'proceedingNumber',        
                        'respondentTechCenterNumber', 'respondentApplicationNumber', 'respondentGroupArtUnitNumber', 'respondentGrantDateStart', 'respondentGrantDateEnd', 'sliderValue', 'proceedingType' ];
    //  add __in variants
    paramsList = paramsList.concat(paramsList.map(param => param + '__in'));

    if (!qParams.has('loadState')) {
      return
    }
    paramsList.forEach(param => {
      if (qParams.has(param)) {
        let value
        if (param === 'documentCategories__in' || param === 'subCategories__in' || param === 'filingParty__in') {
          value = qParams.get(param).split('__');
          for (let v of value) {
            if (param === 'documentCategories__in') {
              setDocumentCategories((prev) => [...prev, v]);
            }
            if (param === 'subCategories__in') {
              setSubCategories((prev) => [...prev, v]);
            }
            if (param === 'filingParty__in') {
              setFilingParty((prev) => [...prev, v]);
            }
          }
        }
        else if (param === 'docFillingStartDate' || param === 'docFillingEndDate' || param === 'respondentGrantDateStart' || param === 'respondentGrantDateEnd') {
          value = moment(qParams.get(param));
        } 
        else {
          value = qParams.get(param);
        }

        if (param === 'documentCategories') {
          setDocumentCategories(value);
        } 
        if (param === 'subCategories') {
          setSubCategories(value);
        }
        if (param === 'filingParty') {
          setFilingParty(value);
        }
        if (param === 'proceedingType') {
          setProceedingType(value);
        }
        if (param === 'docName') {
          setDocName(value);
        }
        if (param === 'petitionerName') {
          setPetitionerName(value);
        }
        if (param === 'docId') {
          setDocId(value);
        }
        if (param === 'docFillingStartDate') {
          setDocFillingStartDate(value);
        }
        if (param === 'docFillingEndDate') {
          setDocFillingEndDate(value);
        }
        if (param === 'docSize') {
          setDocSize(value);
        }
        if (param === 'petitionerCounselName') {
          setpetitionerCounselName(value);
        }
        if (param === 'proceedingNumber') {
          setProceedingNumber(value);
        }
        if (param === 'docSizeOperator') {
          setDocSizeOperator(value);
        }
        if (param === 'respondentInventorName') {
          setRespondentInventorName(value);
        }
        if (param === 'respondentPartyName') {
          setRespondentPartyName(value);
        }
        if (param === 'respondentPatentOwnerName') {
          setRespondentPatentOwnerName(value);
        }
        if (param === 'respondentCounselName') {
          setRespondentCounselName(value);
        }
        if (param === 'respondentPatentNumber') {
          setRespondentPatentNumber(value);
        }
        if (param === 'respondentTechCenterNumber') {
          setRespondentTechCenterNumber(value);
        }
        if (param === 'respondentApplicationNumber') {
          setRespondentApplicationNumber(value);
        }
        if (param === 'respondentGroupArtUnitNumber') {
          setRespondentGroupArtUnitNumber(value);
        }
        if (param === 'respondentGrantDateStart') {
          setRespondentGrantDateStart(value);
        }
        if (param === 'respondentGrantDateEnd') {
          setRespondentGrantDateEnd(value);
        }
        if (param === 'sliderValue') {
          setSliderValue(value);
        }
      }
      // remove loadState from url
      qParams.delete('loadState');
      window.history.replaceState({}, '', `${window.location.pathname}?${qParams}`);
    });
  }


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
            <label> Subproceeding category </label>
            <MultipleSelect 
              label={showTip ? "Select one or multiple" : ""}
              options={subCategoriesLabels}
              labels={subCategoriesLabels}
              value={subCategories} 
              handleChange={handleSubCategoriesChange}
              
            />
        </Grid>

        <Grid item xs={6} sm={3}>
            <label> Document category </label>
            <MultipleSelect 
              label={showTip ? "Select one or multiple" : ""}
              options={documentCategoriesLabels}
              labels={documentCategoriesLabels}
              value={documentCategories} 
              handleChange={handleDocumentCategoriesChange}
            />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Filing party category</label>
            <MultipleSelect 
              label={showTip ? "Select one or multiple" : ""}
              options={filingPartyLabels}
              labels={filingPartyLabels}
              value={filingParty} 
              handleChange={handleFilingPartyChange}
            />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Proceeding type category</label>
            <SelectFilter 
              label={showTip ? "Choose one type" : ""}
              options={proceedingTypeLabels}
              labels={proceedingTypeLabels}
              value={proceedingType} 
              handleChange={handleProceedingTypeChange}
            />
        </Grid>

        <Grid item xs={6} sm={3}>
            <label style={{marginBottom: '4px'}}> Document Title </label>
          <TextField value={docName} id="doctitle" className={classes.filter} onChange={e => setDocName(e.target.value)}
          label={showTip ? "Deposition of Jay Mabrey" : ""} variant="outlined" />
        </Grid>

        <Grid item xs={6} sm={3}>
            <label style={{marginBottom: '4px'}}> Document Id </label>
          <TextField value={docId || ''} id="docid-m" className={classes.filter} onChange={e => setDocId(e.target.value)}
          label={showTip ? "30522574; 235098240; 989986" : ""} variant="outlined" />
        </Grid>

        <Grid item xs={6} sm={3}>
          <label style={{width: '100%'}}> Document filing date </label>
            <div style={{display: 'inline-table'}}> 
              <DatePickerYear startDate={docFillingStartDate} maxDate={docFillingEndDate}
               halfSized={true} setStartDate={setDocFillingStartDate} 
              label={"from"}/>
              <DatePickerYear minDate={docFillingStartDate} halfSized={true} startDate={docFillingEndDate} setStartDate={handleDocFillingEndDateChange} label={"to"}/>
          </div>
        </Grid>

        <Grid item xs={6} sm={3}>
        <label> Proceeding number</label>
          <TextField value={proceedingNumber} onChange={e => setProceedingNumber(e.target.value)}
           className={classes.filter} label={showTip ? "IPR2018-00782; IPR2017-00252" : ""} variant="outlined" />
        </Grid>
        {/* <Grid item xs={6} sm={3}>
            <label style={{marginBottom: '4px'}}> Document Size </label>
            <div style={{display: 'inline-table'}}> 
              <SelectFilter 
                label="operator" 
                options={['>', '<', '=']}
                labels={['>', '<', '=']}
                value={docSizeOperator} 
                handleChange={e=> setDocSizeOperator(e.target.value)}
                width={100}
              />
            <TextField style={{display: 'table-cell'}}  
            type='number' value={docSize} className={classes.filter} 
            onChange={e => setDocSize(e.target.value)}
            label="Kbs" variant="outlined" />
          </div>
        </Grid> */}
      
        <Grid item xs={6} sm={3}>
        <label> Petitioner name</label>
          <TextField size="small" value={petitionerName} onChange={e => setPetitionerName(e.target.value)}
           className={classes.filter} label={showTip ? "Ericsson Inc." : ""} variant="outlined" />
        </Grid>

        <Grid item xs={6} sm={3}>
        <label> Petitioner counsel name</label>
          <TextField size="small" value={petitionerCounselName} 
           onChange={e => setpetitionerCounselName(e.target.value)} label={showTip ? "Brian Oaks" : ""}
           className={classes.filter} variant="outlined" />
        </Grid>

       
        <Grid item xs={12} sm={6}>
        </Grid>

        <Grid item xs={6} sm={3}>
        <label> Respondent inventor name</label>
          <TextField size="small" value={respondentInventorName} onChange={e => setRespondentInventorName(e.target.value)}
           className={classes.filter} label={showTip? "Daniel Rader" : ""} variant="outlined" />
        </Grid>
        {/* <Grid item xs={6} sm={3}>
          <label> Respondent party name*</label>
            <TextField size="small" value={respondentPartyName} onChange={e => setRespondentPartyName(e.target.value)}
            className={classes.filter} label="" variant="outlined" />
        </Grid> */}
        <Grid item xs={6} sm={3}>
          <label> Respondent patent owner name</label>
            <TextField size="small" value={respondentPatentOwnerName} onChange={e => setRespondentPatentOwnerName(e.target.value)}
            className={classes.filter} label={showTip ? "Capella Photonics": ''} variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
          <label> Respondent counsel name</label>
            <TextField size="small" value={respondentCounselName} onChange={e => setRespondentCounselName(e.target.value)}
            className={classes.filter} label={showTip ? "Insogna; Karl Renner": ''} variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
        <label> Respondent patent number</label>
          <TextField size="small" value={respondentPatentNumber} onChange={e => setRespondentPatentNumber(e.target.value)}
           className={classes.filter} label={showTip ? "RE49115": ''} variant="outlined" />
        </Grid>

        <Grid item xs={6} sm={3}>
            <label>Respondent tech center number </label>
          <TextField size="small" value={respondentTechCenterNumber} onChange={e => setRespondentTechCenterNumber(e.target.value)} 
            className={classes.filter} label={showTip? "3900" : ''} variant="outlined" />
        </Grid>

        <Grid item xs={6} sm={3}>
            <label>Respondent application number </label>
          <TextField size="small" value={respondentApplicationNumber} onChange={e => setRespondentApplicationNumber(e.target.value)} 
           className={classes.filter} label={showTip? "12606140; 13513651" : ''} variant="outlined" />
        </Grid>
        
        <Grid item xs={6} sm={3}>
            <label>Respondent group art unit number </label>
          <TextField size="small"value={respondentGroupArtUnitNumber} onChange={e => setRespondentGroupArtUnitNumber(e.target.value)}
           className={classes.filter} label={showTip? "3993" : ''} variant="outlined" />
        </Grid>

        <Grid item xs={6} sm={3}>
          <label style={{width: '100%'}}> Respondent grant date </label>
            <div style={{display: 'inline-table'}}> 
              <DatePickerYear startDate={respondentGrantDateStart} maxDate={respondentGrantDateEnd}
                halfSized={true} setStartDate={setRespondentGrantDateStart} 
              label={"from"}/>
              <DatePickerYear minDate={respondentGrantDateStart}
              halfSized={true} startDate={respondentGrantDateEnd} setStartDate={setRespondentGrantDateEnd} label={"to"}/>
          </div>
        </Grid>

        {/* <Grid item xs={6} sm={3}>
            <label> Judge </label>
          <TextField size="small" className={classes.filter} label="" variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Case Number </label>
          <TextField size="small" className={classes.filter} label="" variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Respondent </label>
          <TextField size="small" className={classes.filter} label="" variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Attorneys for Respondent </label>
          <TextField size="small" className={classes.filter} label="" variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Witness </label>
          <TextField size="small" className={classes.filter} label="" variant="outlined" />
        </Grid>
        <Grid item xs={6} sm={3}>
            <label> Patent </label>
          <TextField size="small" className={classes.filter} label="" variant="outlined" />
        </Grid>
        <Grid container>
            <label style={{width: '100%'}}> Case Filing Date, From-To </label>
            <TextField size="small" className={classes.half} label="From" variant="outlined" />
            <TextField size="small" className={classes.half} label="To" variant="outlined" />
        </Grid>
        <Grid container >
            <label style={{width: '100%'}}> Decision Date, From-To </label>
            <TextField size="small" className={classes.half} label="From" variant="outlined" />
            <TextField size="small" className={classes.half} label="To" variant="outlined" />
        </Grid> */}

        <Grid item xs={12}>
          <span> Search proximity</span>
          <ProximitySlider value={sliderValue} changeHandler={handleSliderChange} />

          <RegularCaseButton onClick={() => resetFilters()} style={{float: 'right'}} variant="text"> 
            <p className='fnt-sz-14 semibold ml'> Reset Filters </p>
          </RegularCaseButton>
        </Grid>
      </Grid>
    </div>
  );
});

export default Filters;
// export {getQueryParams, setUrlParams};